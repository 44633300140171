export const Globals = {
    _vars: [],
    _components: [],

  
    Set: (key, value) => {
        Globals._vars[key] = value;
    },


    Get: (key) => {
        return Globals._vars[key];
    },

    Clear: () => {
        Globals._vars = [];
    },

    Remove: (key) => {
        delete Globals._vars[key];
    },

    AddComponent: (key, component) => {
        Globals._components[key] = component;
    },

    GetComponent: (key) => {
        return Globals._components[key];
    },

    ClearComponents: () => {
        Globals._components = [];
    },

    Run: (key, func, args) => {
        try {
            Globals._components[key][func](args);
        }
        catch (e) {

        }
    },

    Require: (arr) => {
        Object.entries(Globals._components).forEach(([key, value]) => {
            let _haskey = false;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == key) {
                    _haskey = true;
                }
            }
            if (!_haskey) {
                delete Globals._components[key];
            }
        });

        //console.log(Globals._components);
    },

    Debug: () => {
        console.log(Globals._components);
    },
}