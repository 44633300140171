/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { actionCreators } from '../store/Auth';
import { LinkContainer } from 'react-router-bootstrap';
//import { LinkContainer } from 'react-router';
import './Header.css';


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            voiceActivity: false,
            zoom: 100,
        };
    }



    _FullScreen = () => {
        if (!$("body").is(":fullscreen")) {
            document.body.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }


    _HideMenuClick = () => {
        $("body").toggleClass("mini-navbar");
        window.SmoothlyMenu();
    }


    render() {
        const { voiceActivity } = this.state;

        return (
            <nav className="Header navbar " role="navigation">
                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary" data-toggle="popover" data-placement="bottom" data-content="Menu" data-trigger="hover" onClick={() => this._HideMenuClick()}><i className="fa fa-bars"></i> </a>

                </div>
                <ul className="nav navbar-top-links navbar-right">
                    <li>
                        <a className="right-sidebar-toggle" ng-click="ChangeMicrophone()">
                            <i className={'fa fa-microphone' + (voiceActivity ? ' ' :' hide')}></i>
                            <i className={'fa fa-microphone-slash' + (!voiceActivity ? ' ' :' hide')}></i>
                        </a>
                    </li>

                    <li>
                        <span className="m-r-sm text-muted welcome-message"> DL Admin Versão 1.5 </span>
                    </li>

                    <li>
                        <a onClick={() => this._FullScreen()}>
                            <i className="fa fa-external-link"></i>
                        </a>
                    </li>

                    <li>
                        <LinkContainer to={'/'} exact>
                            <a>
                                <i className="fa fa-sign-out"></i> Sair
                            </a>
                        </LinkContainer>
                       
                    </li>
                   
                </ul>

            </nav>
        );
    }
}


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Header);