/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import InfiniteScroll from "react-infinite-scroll-component";
import { RequestPost } from '../../scripts/Request.js';
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import './Decks.css';


class Decks extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            archtypes: [],
            list: [],
            hasMore: true,
            total: 300,
            isLoading: false,
            today: moment(new Date()).format("DD/MM/YYYY"),
            showModal: false,
            addcombo: { AddComboTitle: "", AddComboTable: "", AddComboId: "", AddComboName: "" }
        };
    }


    componentDidMount() {

    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;

        this.LoadArchtypes();
    }


    LoadArchtypes = () => {
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Decks/Archtypes"))
            .then((result) => {
                this.setState({ archtypes: result });
                this.Init();
            });
    }


    Init = async () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Decks/List?page=" + this.page + "&interval=20&offset=" + this.offset))
            .then((result) => {
                result.list.map((data, j) => {
                    data.deckname = data.deckname.length > 13 ? data.deckname.substring(0, 12) : data.deckname;
                    data.name = data.name.length > 13 ? data.name.substring(0, 12) : data.name;

                    data.main_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.main_deck = this.CreateMainDeck(data.main_deck.length, data.main_deck);

                    data.extra_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.extra_deck = this.CreateExtraDeck(data.extra_deck.length, data.extra_deck);

                    data.side_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.side_deck = this.CreateSideDeck(data.side_deck.length, data.side_deck);
                });

                this.setState({
                    total: result.total,
                    list: this.state.list.concat(result.list),
                    isLoading: false
                })
            });

    }


    fetchMoreData = () => {
        if (this.state.list.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Decks/List?page=" + this.page + "&interval=20&offset=" + this.offset))
            .then((result) => {
                result.list.map((data, j) => {
                    data.deckname = data.deckname.length > 13 ? data.deckname.substring(0, 12) : data.deckname;
                    data.name = data.name.length > 13 ? data.name.substring(0, 12) : data.name;

                    data.main_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.main_deck = this.CreateMainDeck(data.main_deck.length, data.main_deck);

                    data.extra_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.extra_deck = this.CreateExtraDeck(data.extra_deck.length, data.extra_deck);

                    data.side_deck.map((item, i) => {
                        item.rarity_img = item.rarity == '' ? 'rare-none.png' : 'rare-' + item.rarity + '.png';
                        item.isXyz = item.types.toLowerCase().indexOf("xyz") > -1;
                        item.statistics = "";
                    });

                    data.side_deck = this.CreateSideDeck(data.side_deck.length, data.side_deck);
                });

                this.setState({
                    list: this.state.list.concat(result.list),
                    isLoading: false 
                })
            });
    };


    _Reload = () => {
        this.setState({
            total: 0,
            list: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    //#region Create Empty decks

    CreateMainDeck(o, arr) {
        for (var i = (o != undefined ? o : 0); i < 30; i++) {
            arr.push({ card_id: 0, name: "", name_br: "", description: "", description_br: "", image: "", rarity: "", rarity_img: "rare-none.png", kind: "", statistics: "", rarity_number: "", isXyz: false });
        }
        return arr;
    }


    CreateExtraDeck(o, arr) {
        for (var i = (o != undefined ? o : 0); i < 12; i++) {
            arr.push({ card_id: 0, name: "", name_br: "", description: "", description_br: "", image: "", rarity: "", rarity_img: "rare-none.png", kind: "", statistics: "", rarity_number: "", isXyz: false });
        }
        return arr;
    }


    CreateSideDeck(o, arr) {
        for (var i = (o != undefined ? o : 0); i < 12; i++) {
            arr.push({ card_id: 0, name: "", name_br: "", description: "", description_br: "", image: "", rarity: "", rarity_img: "rare-none.png", kind: "", statistics: "", rarity_number: "", isXyz: false });
        }
        return arr;
    }
    //#endregion Create Empty decks
   


    _OnArchChange = (deck, event) => {
        let _decks = this.state.list;
        _decks.find(d => d.deck_id == deck.deck_id).arch_id = event.target.value;
        this.setState({ list: _decks });

        Promise.resolve(RequestPost({ token: this.props.user.token, deck_id: deck.deck_id, arch_id: event.target.value }, "api/Decks/ArchChange"))
            .then((result) => {
                
            });
    }


    _HandleNewClick = (item) => {
        var p = prompt("Digite o nome do Arquétipo!");
        if (p != "" && p != null && p != undefined) {
            Promise.resolve(RequestPost({ token: this.props.user.token, name: p  }, "api/Decks/SaveArchtype"))
                .then((result) => {
                    let _dd = { id: result, name: p }
                    let _archtypes = this.state.archtypes;
                    _archtypes.unshift(_dd);
                    let _decks = this.state.list;
                    _decks.find(d => d.deck_id == item.deck_id).arch_id = result;

                    this.setState({ archtypes: _archtypes, list: _decks });
                });
        }
    }



    //#region Modal
    _handleOpenModal = () => {
        this.setState({ showModal: true });
    }


    _handleCloseModal = () => {
        this.setState({ showModal: false });
    }


    _handleSaveModal = () => {
       
    }


    _OnCloseModal = () => {

    }
    //#endregion Modal


    _Delete = (item) => {
        let _decks = this.state.list;
        let index = _decks.findIndex(d => d.deck_id == item.deck_id);
        _decks.splice(index, 1);

        this.setState({ list: _decks });

        Promise.resolve(RequestPost({ token: this.props.user.token, deck_id: item.deck_id }, "api/Decks/Delete"))
            .then((result) => {

            });
    }


    _HandleComboChange = (item, event) => {
        let _archtypes = this.state.archtypes;
        _archtypes.find(a => a.id == item.id).name = event.target.value;

        this.setState({ archtypes: _archtypes });
    }


    _HandleEditClick = (item) => {
        this._EditAddCombo("Arquetipos", "archtypes", "arch_id", "nome");
    }


    _EditAddCombo = (title, table, id_field, name_field) =>  {
        this.state.addcombo.AddComboTitle = title;
        let _addcombo = this.state.addcombo;

        _addcombo.AddComboTable = table;
        _addcombo.AddComboId = id_field;
        _addcombo.AddComboName = name_field;

        this.setState({ addcombo: _addcombo });

        this._handleOpenModal();
    }


    _OnAddComboBlur = (o) => {
        Promise.resolve(RequestPost({ token: this.props.user.token, id: o.id, table: this.state.addcombo.AddComboTable, id_field: this.state.addcombo.AddComboId, name_field: this.state.addcombo.AddComboName, nome: o.name }, "api/Decks/AddComboUpdate"))
            .then((result) => {

            });

    }

    _DeleteAddCombo = (o) => {
        let _archtypes = this.state.archtypes;
        let index = _archtypes.findIndex(a => a.id == o.id);
        _archtypes.splice(index, 1);
        this.setState({ archtypes: _archtypes });

        Promise.resolve(RequestPost({ token: this.props.user.token, id: o.id, table: this.state.addcombo.AddComboTable, id_field: this.state.addcombo.AddComboId, name_field: this.state.addcombo.AddComboName }, "api/Decks/AddComboDelete"))
            .then((result) => {

            });
    }


    render() {
        const { list, today, archtypes } = this.state;

        return (
            <div className="Decks row">
                <LoadingOverlay>
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className="ibox-content ovfl">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3>Lista de Decks</h3>
                                        <p>
                                            A lista inclui todos cadastros.
                                        </p>
                                    </div>
                                </div>

                                <div id="deckbuildermini">
                                    <InfiniteScroll
                                        dataLength={this.state.list.length}
                                        next={this.fetchMoreData}
                                        hasMore={this.state.hasMore}
                                        loader={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Carregando...</b>
                                            </p>
                                        }
                                        _style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Não há mais dados</b>
                                            </p>
                                        }>

                                        {list.map((item, i) => (
                                            <div key={'deck'+i} className="deck_group">
                                                <buttom className="btn btn-danger" style={{ width: '100%' }} onClick={() => this._Delete(item)}>Apagar</buttom>
                                                <div className="list-group-item" style={{ border: 0 }}>
                                                    <span className="pull-right"> Arquétipo* <sup>[ <a title="Editar Arquétipos" onClick={() => this._HandleEditClick(item)}>Editar</a> ]</sup></span>
                                                    <table border="0" cellPadding="0" cellSpacing="0" style={{ width: '100%', margin: 0, padding: 0 }}>
                                                        <tbody>
                                                            <tr style={{ margin: 0, padding: 0 }}>
                                                                <td style={{ width: 'auto', margin: 0, padding: 0 }} width="90%">
                                                                    <select id={'arch_id' + i} name={'arch_id' + i} style={{ margin: 0, padding: 0 }} value={item.arch_id} className="form-control fg-input" aria-label={'arch_id' + i} aria-describedby="basic-addon2" onChange={(event) => this._OnArchChange(item, event)}>
                                                                        <option value="">Selecione um Arquétipo </option>
                                                                        {archtypes.map((arch, j) => (
                                                                            <option key={'arch' + j} value={arch.id}>{arch.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td style={{ margin: 0, padding: 0 }} width="10%">
                                                                    <button className="btn btn-white" style={{ margin: 0, padding: 0, height: '34px', width: '70px' }} type="button" onClick={() => this._HandleNewClick(item)}>New</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <hr style={{ borderColor: '#000000' }} />
                                                <img className={'new_deck' + (item.data_cadastro == today ? '' : ' hide')} src="/img/deckbuilder/novo.png" />
                                                <time>{item.data_cadastro}</time>

                                                <a>
                                                    <h4>{item.deckname} - {item.name}</h4>
                                                    <div className="stage">
                                                        <div>
                                                            <center>
                                                                <span className="skillname"><img src="img/deckbuilder/skill.png" width="18" height="18" /> {item.habilidade}</span>
                                                            </center>
                                                        </div>
                                                        {item.main_deck.map((card, j) => (
                                                            <div key={'md' + j} className="card">
                                                                <img className="rarityimg" src={'img/deckbuilder/' + card.rarity_img} />
                                                                <div className="card_info" title={card.name_br}>
                                                                    <div className="add-icon">{(j + 1)}</div>
                                                                    <img className={'cardimg' + (card.image == '' ? ' hide' : '')} src={'https://images.weserv.nl/?w=35&h=50&url=' + card.image + '&temp'} width="35" height="50" alt={card.name_br} />
                                                                </div>
                                                                <img className={'limit1img' + (card.limit != 1 ? ' hide' : '')} src="img/deckbuilder/limit1.png" width="18" height="18" />
                                                                <img className={'limit2img' + (card.limit != 2 ? ' hide' : '')} src="img/deckbuilder/limit2.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 3 ? ' hide' : '')} src="img/deckbuilder/limit3.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 4 ? ' hide' : '')} src="img/deckbuilder/ban.png" width="18" height="18" />
                                                            </div>
                                                        ))}
                                                        <div className="clear"></div>
                                                        <hr className="hrblack" />


                                                        <div><center><h6>Extra Deck</h6></center></div>
                                                        {item.extra_deck.map((card, j) => (
                                                            <div key={'ed' + j} className="card">
                                                                <img className="rarityimg" src={'img/deckbuilder/' + card.rarity_img} />
                                                                <div className="card_info_ex" title={card.name_br}>
                                                                    <div className="add-icon_ex">{(j + 1)}</div>
                                                                    <img className={'cardimg' + (card.image == '' ? ' hide' : '')} src={'https://images.weserv.nl/?w=35&h=50&url=' + card.image + '&temp'} width="35" height="50" alt={card.name_br} />
                                                                </div>
                                                                <img className={'limit1img' + (card.limit != 1 ? ' hide' : '')} src="img/deckbuilder/limit1.png" width="18" height="18" />
                                                                <img className={'limit2img' + (card.limit != 2 ? ' hide' : '')} src="img/deckbuilder/limit2.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 3 ? ' hide' : '')} src="img/deckbuilder/limit3.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 4 ? ' hide' : '')} src="img/deckbuilder/ban.png" width="18" height="18" />

                                                            </div>
                                                        ))}
                                                        <div className="clear"></div>
                                                        <hr className="hrblack" />


                                                        <div><center><h6>Side Deck</h6></center></div>
                                                        {item.side_deck.map((card, j) => (
                                                            <div key={'sd' + j} className="card">
                                                                <img className="rarityimg" src={'img/deckbuilder/' + card.rarity_img} />
                                                                <div className="card_info_ex" title={card.name_br}>
                                                                    <div className="add-icon_ex">{(j + 1)}</div>
                                                                    <img className={'cardimg' + (card.image == '' ? ' hide' : '')} src={'https://images.weserv.nl/?w=35&h=50&url=' + card.image + '&temp'} width="35" height="50" alt={card.name_br} />
                                                                </div>
                                                                <img className={'limit1img' + (card.limit != 1 ? ' hide' : '')} src="img/deckbuilder/limit1.png" width="18" height="18" />
                                                                <img className={'limit2img' + (card.limit != 2 ? ' hide' : '')} src="img/deckbuilder/limit2.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 3 ? ' hide' : '')} src="img/deckbuilder/limit3.png" width="18" height="18" />
                                                                <img className={'limit3img' + (card.limit != 4 ? ' hide' : '')} src="img/deckbuilder/ban.png" width="18" height="18" />

                                                            </div>
                                                        ))}
                                                        <div className="clear"></div>
                                                    </div>
                                                </a>




                                            </div>
                                        ))}

                                    </InfiniteScroll>
                                </div>

                            </div>
                        </div>
                    </div>


                    <Modal show={this.state.showModal} onHide={this._handleCloseModal} animation={false} dialogClassName="modal900">
                        <Modal.Header>
                            <Modal.Title>{this.state.addcombo.AddComboTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-hover Decks">
                                <tbody>
                                    {archtypes.map((item, i) => (
                                    <tr>
                                        <td> <input id={'combo'+i} type="text" value={item.name} className="form-control inputfull" onBlur={() => this._OnAddComboBlur(item)} onChange={(event) => this._HandleComboChange(item, event)}/></td>
                                        <td className="tdaction"><button className="btn btn-white btn-sm btn_action" type="button" onClick={() => this._DeleteAddCombo(item)}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this._handleCloseModal}>
                                Fechar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Decks);