import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Auth';
import NavMenu from './NavMenu';
import Header from './Header';
import Footer from './Footer';
import { Redirect, Route } from "react-router";
import './Layout.css';

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoged: true
        };
    }

    componentWillReceiveProps(nextProps) {
        
    }


    componentWillUpdate() {
        
    }

    componentDidUpdate() {

    }

    componentWillMount() {
        let path = window.location.href.toLowerCase().replace('https://', '').replace('http://', '').split("/");

        if (this.props.user.name == '' && path[1] != "login" && path[1] != "") {
            this.setState({ isLoged: false });
            window.location = "/Login";
        }


    }




    render() {
        const { isLoged } = this.state;

        return (
            <div id="wrapper" className={isLoged?'':'hide'}>
                {(this.props.user.name == '' ? '' : (
                    <nav className="navbar-default navbar-static-side" role="navigation">
                        <NavMenu />
                    </nav>
                ))}

                <div id={this.props.user.name == '' ? '' : 'page-wrapper'} className={this.props.user.name == '' ? '' : 'gray-bg _sidebar-content'}>
                    {(this.props.user.name == '' ? '' : (
                        <div className="row border-bottom">
                            <Header />
                        </div>
                    ))}
                    <div className="wrapper wrapper-content">
                        {this.props.children}
                    </div>
                    {(this.props.user.name == '' ? '' : (
                        <div className="footer">
                           <Footer />
                        </div>
                    ))}
                </div>


            </div>
        );
    }
} 


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Layout);
