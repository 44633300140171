/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { RequestPost } from '../../scripts/Request.js';
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import './Cartas.css';


class Cartas extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            selected_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" },
            new_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" },
            search: { box_id: "", word: "", type: "" },
            boxes: [],
            isLoading: false,
            showModal: false
        }
    }


    componentDidMount() {

    }

    componentWillMount() {
        this.page = 0;
        this.offset = 0;

        this.Init();
        this.LoadBoxes();
    }


    Init = async () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ box_id: this.state.search.box_id, word: this.state.search.word, type: this.state.search.type, token: this.props.user.token }, "api/Cartas/List?page=" + this.page + "&interval=100&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    cards: this.state.cards.concat(data)
                })
                this.setState({ isLoading: false });
            });

    }


    LoadBoxes= async () => {
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Cartas/Boxes"))
            .then((data) => {
                data.map((item, i) => {
                    item.name = item.name.replace("- 0", "- Main").replace("- 1", "- Mini").replace("- 2", "- Estrutural").replace("- 3", "- Selection");
                });
                this.setState({
                    boxes: data
                })
            });
    }


    _Reload = () => {
        this.setState({
            cards: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _More = () => {
        this.page++;
        this.offset++;
        this.Init();
    }


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }

        if (event.target.id == "header") {
            this.Init(event.target.value);
        }
    }

    _HandlerSearchChange = (event) => {
        let _search = this.state.search;
        _search[event.target.id] = event.target.value;

        this.setState({ search: _search});
    }


    _HandleNewPropertyChange = (event) => {
        let _card = this.state.new_card;
        _card[event.target.id] = event.target.value;
        this.setState({ new_card: _card });
    }


    _HandlePropertyChange = (event) => {
        let _card = this.state.selected_card;
        _card[event.target.id] = event.target.value;
        this.setState({ selected_card: _card });
    }


    _OnCardClick = (card) => {
        this.setState({ selected_card: card });
    }


    _SaveCard = () => {
        let _card = Object.assign(this.state.selected_card, { token: this.props.user.token });
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Caixas/SaveCard"))
            .then((data) => {
                this.setState({
                    selected_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" }
                });
                this.setState({ isLoading: false });
            });
    }

    //#region Modal
    _handleOpenModal = () => {
        this.setState({ showModal: true });
    }


    _handleCloseModal = () => {
        this.setState({ showModal: false });
    }


    _handleSaveModal = () => {
        let _card = Object.assign(this.state.new_card, { token: this.props.user.token });
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Cartas/SaveCard"))
            .then((data) => {
                let _cards = this.state.cards;
                _cards.push(this.state.new_card);
                this.setState({
                    cards: _cards,
                    new_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" },
                    isLoading: false,
                    showModal: false
                });

            });
    }


    _OnCloseModal = () => {

    }
    //#endregion Modal



    render() {
        let { boxes, search, new_card, cards } = this.state;

        return (
            <div className="Cartas">
                <LoadingOverlay>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Caixa</h5>
                                    <label className="label label-default pull-right btn" onClick={this._handleOpenModal}> Adicionar carta </label>
                                </div>

                                <div className="ibox-content">
                                    <div className="input-group">
                                        <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={this._HandlerSearchChange}/>

                                        <span className="input-group-btn">
                                            <select id="box_id" className="input form-control" value={search.box_id} style={{ width: '150px', marginTop:'-5px' }} onChange={this._HandlerSearchChange}>
                                                <option value="">Todas as Caixas</option>
                                                {boxes.map((item, i) => (
                                                    <option key={'box' + i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </span>

                                        <span className="input-group-btn">
                                            <select id="type" className="input form-control" value={search.type} style={{ width: '150px', marginTop: '-5px' }} onChange={this._HandlerSearchChange}>
                                                <option value="">Todas as Cartas</option>
                                                <option value="0">Sem Tradução</option>
                                                <option value="1">Sem Raridade</option>
                                                <option value="2">Com limitação</option>
                                            </select>
                                        </span>

                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn btn-primary" onClick={() => { this._Reload()}}> <i className="fa fa-search"></i> Busca</button>
                                        </span>

                                    </div>

                                    <div id="cards_list">
                                        {cards.map((item, i) => (
                                            <div key={'card'+i} className="dcard">
                                                <img src={'/img/deckbuilder/rare-' + item.rarity +'.png'} width="50" height="12" /><br />
                                                <div>
                                                    <div id={'exdeck_cardA' + i} onClick={() => this._OnCardClick(item) } style={{ cursor: 'pointer' }}>
                                                        <div className="imgCard ccard">
                                                            <img className={'cardimg _tcard' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=69&h=100&url=' + item.image + '&temp'} width="69" height="100" alt="" />
                                                            <img className={'limit1img limit' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img limit' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img limit' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img limit' + (item.limit == 4? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <span>{ item.card_id }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <center>
                                            <button className="btn btn-white btn-sm" title="Salva Carta" type="button" onClick={() => this._More()}><i className="fa fa-plus"></i> Carregar mais 100</button>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="ibox ">
                                <div className="ibox-content" id="card_info">
                                    <div className="row" style={{ height: '200px' }}>
                                        <center>
                                            <img src={'https://images.weserv.nl/?w=138&h=200&url=' + this.state.selected_card.image + '&temp'} width="138" height="200" alt={this.state.selected_card.name_br} ng-show="item.image!=''" />
                                        </center>
                                    </div>

                                    <div className="row">
                                        <ul className="list-group clear-list">
                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Raridade </span>
                                                <select id="rarity" className="form-control" value={this.state.selected_card.rarity} onChange={this._HandlePropertyChange}>
                                                    <option value="">Escolha uma raridade</option>
                                                    <option value="ur">UR - Ultra Rara</option>
                                                    <option value="sr">SR - Super Rara</option>
                                                    <option value="r">R - Rara</option>
                                                    <option value="n">N - Normal</option>
                                                </select>
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Imagem da carta <sup>não use o images.weserv.nl</sup></span>
                                                <input id="image" type="text" className="form-control" value={this.state.selected_card.image} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Nome em ingles </span>
                                                <input id="name" type="text" className="form-control" value={this.state.selected_card.name} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Nome em português </span>
                                                <input id="name_br" type="text" className="form-control" value={this.state.selected_card.name_br} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Descrição em ingles </span>
                                                <textarea id="description" className="form-control" style={{ height: '150px' }} value={this.state.selected_card.description} onChange={this._HandlePropertyChange}>{this.state.selected_card.description}</textarea>
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Descrição português </span>
                                                <textarea id="description_br" className="form-control" style={{ height: '150px' }} value={this.state.selected_card.description_br} onChange={this._HandlePropertyChange}>{this.state.selected_card.description_br}</textarea>
                                            </li>


                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Tipo em ingles </span>
                                                <input id="types" type="text" className="form-control" value={this.state.selected_card.types} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Tipo em português </span>
                                                <input id="types_br" type="text" className="form-control" value={this.state.selected_card.types_br} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Atributo em ingles </span>
                                                <input id="attribute" type="text" className="form-control" value={this.state.selected_card.attribute} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Atributo em português </span>
                                                <input id="attribute_br" type="text" className="form-control" value={this.state.selected_card.attribute_br} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Nivel </span>
                                                <input id="level" type="text" className="form-control" value={this.state.selected_card.level} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Ataque </span>
                                                <input id="atk" type="text" className="form-control" value={this.state.selected_card.atk} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Def/link </span>
                                                <input id="def" type="text" className="form-control" value={this.state.selected_card.def} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> É um monstro de extra deck (1 - sim, 0 - não)</span>
                                                <input id="isextra" type="text" className="form-control" value={this.state.selected_card.isextra} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Limitação (use 4 para banido)</span>
                                                <input id="limit" type="text" className="form-control" value={this.state.selected_card.limit} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Código (Duellinks) </span>
                                                <input id="code" type="text" className="form-control" value={this.state.selected_card.code} onChange={this._HandlePropertyChange} />
                                            </li>

                                            <li className="list-group-item fist-item">
                                                <span className="pull-right"> Código (Banco Local) </span>
                                                <input id="card_id" type="text" className="form-control" value={this.state.selected_card.card_id} onChange={this._HandlePropertyChange} />
                                            </li>
                                        </ul>

                                        <br />
                                        <button className="btn btn-white btn-sm" title="Salva Carta" type="button" disabled={!(this.state.selected_card.card_id != '' && this.state.selected_card.card_id != undefined && this.state.selected_card.card_id != null)} onClick={() => this._SaveCard()}><i className="fa fa-check"></i> Salvar Carta</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showModal} onHide={this._handleCloseModal} animation={false} dialogClassName="modal900">
                        <Modal.Header>
                            <Modal.Title> Inserir nova carta </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '250px' }} valign="top">
                                            <center>
                                                <img src={'https://images.weserv.nl/?w=138&h=200&url=' + new_card.image + '&temp'} width="138" height="200" alt={new_card.name_br} className={new_card.image != '' ? '' : ' hide'} />
                                            </center>
                                        </td>

                                        <td>
   
                                            <ul className="list-group clear-list">
                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Raridade </span>
                                                    <select id="rarity" className="form-control" value={new_card.rarity} onChange={this._HandleNewPropertyChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR - Ultra Rara</option>
                                                        <option value="sr">SR - Super Rara</option>
                                                        <option value="r">R - Rara</option>
                                                        <option value="n">N - Normal</option>
                                                    </select>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Imagem da carta <sup>não use o images.weserv.nl</sup></span>
                                                    <input id="image" type="text" value={new_card.image} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em ingles </span>
                                                    <input id="name" type="text" value={new_card.name} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em português </span>
                                                    <input id="name_br" type="text" value={new_card.name_br} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição em ingles </span>
                                                    <textarea id="description" value={new_card.description} className="form-control" style={{ height: '200px' }} onChange={this._HandleNewPropertyChange}></textarea>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição português </span>
                                                    <textarea id="description_br" value={new_card.description_br} className="form-control" style={{ height: '200px' }} onChange={this._HandleNewPropertyChange}></textarea>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em ingles </span>
                                                    <input id="types" type="text" value={new_card.types} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em português </span>
                                                    <input id="types_br" type="text" value={new_card.types_br} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em ingles </span>
                                                    <input id="attribute" type="text" value={new_card.attribute} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em português </span>
                                                    <input id="attribute_br" type="text" value={new_card.attribute_br} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nivel </span>
                                                    <input id="level" type="text" value={new_card.level} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Ataque </span>
                                                    <input id="atk" type="text" value={new_card.atk} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Def </span>
                                                    <input id="def" type="text" value={new_card.def} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> É um monstro de extra deck (1 - sim, 0 - não)</span>
                                                    <input id="isextra" type="text" value={new_card.isextra} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Limitação </span>
                                                    <input id="limit" type="text" value={new_card.limit} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Duellinks) </span>
                                                    <input id="code" type="text" value={new_card.code} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Banco Local) </span>
                                                    <input id="card_id" type="text" value={new_card.card_id} className="form-control" onChange={this._HandleNewPropertyChange} />
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this._handleCloseModal}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={this._handleSaveModal}>
                                Salvar Carta
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Cartas);