/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { RequestPost } from '../../scripts/Request.js';
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import './Archtypes.css';


class Archtypes extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            selected_archtype: {id: "", nome:""},
            archtypes: [],
            cards: [],
            boxes: [],
            search: { box_id: "", word: ""},
            isLoading: false,
            showModal: false
        }
    }


    componentDidMount() {

    }

    componentWillMount() {
        this.setState({ selected_archtype: { id: "", nome: "" }, search: { box_id: "", word: "" } });

        this.page = 0;
        this.offset = 0;

        //this.Init();
        this.LoadBoxes();
        this.LoadArchtypes();
    }


    Init = async () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ box_id: this.state.search.box_id, word: this.state.search.word, token: this.props.user.token }, "api/Cartas/List?page=" + this.page + "&interval=100&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    cards: this.state.cards.concat(data)
                })
                this.setState({ isLoading: false });
            });

    }


    LoadBoxes = async () => {
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Cartas/Boxes"))
            .then((data) => {
                data.map((item, i) => {
                    item.name = item.name.replace("- 0", "- Main").replace("- 1", "- Mini").replace("- 2", "- Estrutural").replace("- 3", "- Selection");
                });
                this.setState({
                    boxes: data
                })
            });
    }


    LoadArchtypes = async () => {
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Archtypes/List"))
            .then((data) => {
                this.setState({
                    archtypes: data
                })
            });
    }


    _Reload = () => {
        this.setState({
            cards: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _More = () => {
        this.page++;
        this.offset++;
        this.Init();
    }


    _HandlerSearchChange = (event) => {
        let _search = this.state.search;
        _search[event.target.id] = event.target.value;

        this.setState({ search: _search });
    }


    _HandleNewClick = (item) => {
        let p = prompt("Digite o nome do Arquétipo!");
        if (p != "" && p != null && p != undefined) {
            Promise.resolve(RequestPost({ token: this.props.user.token, name: p }, "api/Decks/SaveArchtype"))
                .then((result) => {
                    let _dd = { id: result, name: p }
                    let _archtypes = this.state.archtypes;
                    _archtypes.unshift(_dd);
                    let _decks = this.state.list;
                    _decks.find(d => d.deck_id == item.deck_id).arch_id = result;

                    this.setState({ archtypes: _archtypes, list: _decks });
                });
        }
    }


    _SelectArchtype = (item) => {
        this.setState({ selected_archtype: item });
        this.page = 0;
        this.offset = 0;

        Promise.resolve(RequestPost({ id: item.id, token: this.props.user.token }, "api/Archtypes/Cards"))
            .then((data) => {
                this.setState({
                    list: data
                });

                this._GetBoss(item);
            });
    }


    _GetBoss = (item) => {
        Promise.resolve(RequestPost({id: this.state.selected_archtype.id, token: this.props.user.token }, "api/Archtypes/GetBoss"))
            .then((data) => {
                let _list = this.state.list;
                for (var i = 0; i < _list.length; i++) {
                    if (_list[i].card_id == eval(data)) {
                        _list[i].isboss = true;
                    } else {
                       _list[i].isboss = false;
                    }
                }

                this.setState({
                    list: _list
                });
            });
    } 



    _OnCardClick = (item) => {
        if (this.state.selected_archtype.id != "") {
            let _list = this.state.list;
            _list.push(item);

            this.setState({
                list: _list
            });

            Promise.resolve(RequestPost({ card_id: item.card_id, arch_id: this.state.selected_archtype.id, token: this.props.user.token }, "api/Archtypes/AddTo"))
                .then((data) => {

                });
        }
    }

    _OnMetaChange = (item, event) => {
        if (this.state.selected_archtype.id != "" && event.target.checked) {
            let _list = this.state.list;
            _list.find(l => l.card_id == item.card_id).isboss = event.target.checked;

            for (var i = 0; i < _list.length; i++) {
                if (_list[i].card_id != item.card_id) {
                    _list[i].isboss = false;
                }
            }

            this.setState({
                list: _list
            });

            Promise.resolve(RequestPost({ card_id: item.card_id, arch_id: this.state.selected_archtype.id, token: this.props.user.token }, "api/Archtypes/Boss"))
                .then((data) => {

                });
        }
    }


    _OnCardClickR = (item) => {
        if (this.state.selected_archtype.id != "") {
            let _list = this.state.list;
            let index = _list.findIndex(l => l.id == item.id);
            _list.splice(index, 1);

            this.setState({
                list: _list
            });


            Promise.resolve(RequestPost({ card_id: item.card_id, arch_id: this.state.selected_archtype.id, token: this.props.user.token }, "api/Archtypes/RemoveFrom"))
                .then((data) => {

                });
        }
    }


    render() {
        let { list, cards, boxes, search, archtypes, selected_archtype } = this.state;

        return (
            <div className="Archtypes">
                <LoadingOverlay>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Arquétipos <span style={{ color: 'red' }}>{selected_archtype.name }</span></h5>
                                    <label className="label label-default pull-right btn" onClick={this._HandleNewClick}> Adicionar Arquétipo </label>
                                </div>

                                <div className="ibox-content">
                                    <div className="input-group">
                                        <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={this._HandlerSearchChange} />

                                        <span className="input-group-btn">
                                            <select id="box_id" className="input form-control" value={search.box_id} style={{ width: '150px', marginTop: '-5px' }} onChange={this._HandlerSearchChange}>
                                                <option value="">Todas as Caixas</option>
                                                {boxes.map((item, i) => (
                                                    <option key={'box' + i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </span>

                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn btn-primary" onClick={() => { this._Reload() }}> <i className="fa fa-search"></i> Busca</button>
                                        </span>

                                    </div>

                                    <div id="cards_list">
                                        {cards.map((item, i) => (
                                            <div key={'card' + i} className="dcard">
                                                <img src={'/img/deckbuilder/rare-' + item.rarity + '.png'} width="50" height="12" /><br />
                                                <div>
                                                    <div id={'exdeck_cardA' + i} onClick={() => this._OnCardClick(item)} style={{ cursor: 'pointer' }}>
                                                        <div className="_acard">
                                                            <img className={'cardimg _tcard' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=69&h=100&url=' + item.image + '&temp'} width="69" height="100" alt="" />
                                                        </div>
                                                        <span>{item.card_id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <center>
                                            <button className="btn btn-white btn-sm" title="Salva Carta" type="button" onClick={() => this._More()}><i className="fa fa-plus"></i> Carregar mais 100</button>
                                        </center>
                                    </div>

                                    <hr />

                                    {list.map((item, i) => (
                                        <div key={'cardl' + i} className="dcard">
                                            <img src={'/img/deckbuilder/rare-' + item.rarity + '.png'} width="50" height="12" /><br />
                                            <div>
                                                <div id={'exdeck_cardB' + i} onClick={() => this._OnCardClickR(item)} style={{ cursor: 'pointer' }}>
                                                    <div className="_acard">
                                                        <img className={'cardimg _tcard' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=69&h=100&url=' + item.image + '&temp'} width="69" height="100" alt="" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type="checkbox" id={'scar' +i} className="icheck" checked={item.isboss} onChange={(event) => this._OnMetaChange(item, event)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="ibox">
                                <div className="ibox-content" id="arch_list">
                                    <div className="row">
                                        <input type="text" placeholder="Buscar por nome" className="input form-control" />

                                        <table className="table table-striped table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <td style={{ width: '200px' }}>Arquétipo</td>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {archtypes.map((item, i) => (
                                                    <tr key={'archk' + i}>
                                                        <td><a onClick={() => this._SelectArchtype(item)}>{ item.name }</a></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Archtypes);