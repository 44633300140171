/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import { RequestPost } from '../../scripts/Request.js';
import moment from 'moment';
import './Caixas.css';


class Caixas extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            hasMore: true,
            total: 300,
            word: "",
            type: ""
        }

        this._handlerWordChange = this._handlerWordChange.bind(this);
        this._HandlerChange = this._HandlerChange.bind(this);
    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;

        this.Init();
    }


    Init = async () => {
        Promise.resolve(RequestPost({ word: this.state.word, type: this.state.type, token: this.props.user.token }, "api/Caixas/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.state.total = data.total;

                this.setState({
                    total: data.total,
                    items: this.state.items.concat(data.list)
                });
            });
    }


    fetchMoreData = () => {
        if (this.state.items.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        Promise.resolve(RequestPost({ word: this.state.word, type: this.state.type, token: this.props.user.token }, "api/Caixas/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    items: this.state.items.concat(data.list)
                })
            });
    };


    _handlerWordChange = (event) => {
        let _word = event.target.value;
        this.setState({ word: _word });
    }


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }

        if (event.target.id == "header") {
            this.Init(event.target.value);
        }
    }


    _Reload = () => {
        this.setState({
            total: 0,
            items: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _OnBoxClick = (item) => {
        this.props.history.push("/Caixa/" + item.box_id);
    }


    _GetType = (type) => {
        switch (eval(type)) {
            case 0:
                return 'Main';
                break;
            case 1:
                return 'Mini';
                break;
            case 2:
                return 'Estrutural';
                break;
            case 3:
                return 'Selection';
                break;
            case 4:
                return 'Avulso';
                break;
            default:
                return '';
        }
    }



    render() {
        return (
            <div className="Caixas">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="ibox">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3>Lista de Caixas</h3>
                                        <p>
                                            A lista inclui todos cadastros.
                                        </p>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <input type="text" placeholder="Buscar por nome" className="input form-control" value={this.state.word} onChange={this._handlerWordChange} />

                                    <span className="input-group-btn">
                                        <select className="form-control" id="type" value={this.state.type} onChange={this._HandlerChange}>
                                            <option value="">Todas as Caixas</option>
                                            <option value="0">Main</option>
                                            <option value="1">Mini</option>
                                            <option value="2">Estrutural</option>
                                            <option value="3">Selection</option>
                                            <option value="4">Avulso</option>
                                        </select>
                                    </span>

                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn btn-primary" onClick={() => this._Reload()}> <i className="fa fa-search"></i> Busca</button>
                                    </span>
                                </div>

                                <div className="row">
                                    <hr />
                                    <div className="col-sm-12">
                                  
                                        <div className="table">
                                            <InfiniteScroll
                                                dataLength={this.state.items.length}
                                                next={this.fetchMoreData}
                                                hasMore={this.state.hasMore}
                                                loader={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Carregando...</b>
                                                    </p>
                                                }
                                                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Não há mais dados</b>
                                                    </p>
                                                }>

                                                {this.state.items.map((item, i) => (
                                                    <div key={'item' + i} className="tr">
                                                        <div className="td client-avatar"><img src="img/usera.jpg" alt=""/></div>
                                                        <div className="td boxname" onClick={() => this._OnBoxClick(item)}>{item.name}</div>
                                                        <div className="td boxtype" onClick={() => this._OnBoxClick(item)}>{this._GetType(item.type)}</div>
                                                        <div className="td action"><span className="label label-primary">{moment(item.data).format('DD/MM/YYYY')}</span></div>
                                                        <div className="cleaner">&nbsp;</div>
                                                    </div>
                                                ))}
                                            </InfiniteScroll>
                                        </div>
                                    </div>

                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Caixas);