/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { UploadService } from '../../scripts/UploadService';
import { Globals } from '../../scripts/Globals.js';
import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import moment from 'moment';
import $ from 'jquery';
import './FanArts.css';



class FanArts extends Component {
    domain = "";
    list = [];
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            hasMore: true,
            total: 300,
            word: "",
            isLoading: false
        };

        this._HandlerChange = this._HandlerChange.bind(this);
        this._HandlerListChange = this._HandlerListChange.bind(this);
    }



    componentDidMount() {

    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;

        this.Init();
    }


    componentWillReceiveProps(nextProps) {

    }


    Init = async () => {
        Promise.resolve(RequestPost({ word: this.state.word }, "api/FanArts/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.state.total = data.total;

                this.setState({
                    total: data.total,
                    list: this.state.list.concat(data.list)
                });
            });
    }


    fetchMoreData = () => {
        if (this.state.items.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        Promise.resolve(RequestPost({ word: this.state.word }, "api/FanArts/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    list: this.state.list.concat(data.list)
                })
            });
    };


    _Reload = () => {
        this.setState({
            total: 0,
            list: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _UploadImages = (selector) => {
        document.getElementById(selector).click();
        $(document.getElementById(selector)).bind('change', (e) => {
            this.setState({ isLoading: true });
            let arr = document.getElementById(selector).files;

            let uploadService = new UploadService();
            for (let i = 0; i < arr.length; i++) {
                console.log(i);
                uploadService.Queue(arr[i]);
            }
            uploadService.Start("api/FanArts/Upload", (result) => {
                //OnProgress
                console.log("OnProgress", result.percent);
            }, (result) => {
                //OnEnd
                console.log("OnEnd", result);
                this.setState({
                    list: this.state.list.concat({ img: this.domain + result.img, autor: "" }),
                    isLoading: false
                });
            });
        });
    }


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }

        if (event.target.id == "header") {
            this.Init(event.target.value);
        }
    }


    _HandlerListChange = (value, index) => {
        const _list = [...this.state.list];
        _list[index].autor = value;
        this.setState({ list: _list });


    }


    _DeleteItem = (item) => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ img: item.img, token: this.props.user.token }, "api/FanArts/Delete"))
            .then((data) => {
                if (data == "loaded") {
                    this.setState({ isLoading: false });
                    this._Reload();
                }
            });
    }


    _OnAutorBlur = (item, index) => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ img: item.img, autor: item.autor, token: this.props.user.token }, "api/FanArts/Autor"))
            .then((data) => {
                if (data == "loaded") {
                    this.setState({ isLoading: false });
                }
            });
    }


    _OnGenerate = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/FanArts/GenarateStaticPages"))
            .then((data) => {
                if (data == "loaded") {
                    this.setState({ isLoading: false });
                }
            });
    }


    render() {
        return (
            <div id="fanarts" className="Fanarts row">
                <div className="col col-sm-8">
                    <div className="box">
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2>Lista de Imagens</h2>
                                    <input id="uploadfiles" name="uploadfiles" className="hide" type="file" accept="image/*" multiple="true" />
                                    <button className="pull-right" onClick={() => this._UploadImages('uploadfiles')}>Enviar Imagens</button>
                                    <button className="pull-right" onClick={() => this._OnGenerate()}>Gerar Páginas Estáticas</button>
                                </div>
                                </div>

                                <div className="row">
                                    <br />

                                    <Tabs className="tabActiveLinkStyle">
                                        <TabLink to="tab1" className="tabLink">Imagens</TabLink>


                                        <TabContent for="tab1" className="tabContent">
                                            <div className="table">
                                                <InfiniteScroll
                                                    dataLength={this.state.list.length}
                                                    next={this.fetchMoreData}
                                                    hasMore={this.state.hasMore}
                                                    loader={
                                                        <p style={{ textAlign: "center" }}>
                                                            <b>Carregando...</b>
                                                        </p>
                                                    }
                                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                            <b>Não há mais dados</b>
                                                        </p>
                                                    }>

                                                    {this.state.list.map((item, i) => (
                                                        <div key={'item' + i} className="tr">
                                                            <div className="td data"><a href={'/db/'+item.img} target="_blank">{item.img}</a></div>
                                                            <div className="td title"><input type="text" placeholder="Autor" id={'autor'+i} className="input form-control" value={item.autor} onBlur={() => { this._OnAutorBlur(item, i) }} onChange={(e) => this._HandlerListChange(e.target.value, i)} /></div>
                                                            <div className="td action"><button className="btn btn-white btn-sm" type="button" onClick={() => this._DeleteItem(item)}><i className="fa fa-trash"></i></button></div>
                                                            <div className="cleaner">&nbsp;</div>
                                                        </div>
                                                    ))}
                                                </InfiniteScroll>
                                            </div>


                                        </TabContent>
                                    </Tabs>


                                </div>
                         
                        </div>
                    </div>
                </div>

                <div className="col col-sm-4">

                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FanArts);