/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import InfiniteScroll from "react-infinite-scroll-component";
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import { RequestPost } from '../../scripts/Request.js';
import moment from 'moment';

import './Posts.css';

class Posts extends Component {
    posts = [];
    page = 0;
    offset = 0;
   

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            hasMore: true,
            total: 300,
            word: ""
        }

        this._handerWordChange = this._handerWordChange.bind(this);
        this._OnAtivoClick = this._OnAtivoClick.bind(this); 
        this._OnPostClick = this._OnPostClick.bind(this); 
    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    Init = async() => {
        Promise.resolve(RequestPost({word: this.state.word}, "api/Posts/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.state.total = data.total;

                this.setState({
                    total: data.total,
                    items: this.state.items.concat(data.list)
                });
            });
    }



    fetchMoreData = () => {
        if (this.state.items.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        Promise.resolve(RequestPost({ word: this.state.word }, "api/Posts/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    items: this.state.items.concat(data.list)
                })
            });
    };



    _handerWordChange = (event) => {
        let _word = event.target.value;
        this.setState({ word: _word });
    }


    _Reload = () => {
        this.setState({
            total: 0,
            items: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _OnPostClick = (item) => {
        this.props.history.push("/Post/" + item.post_id);
    }


    _OnAtivoClick = (item, index) => {
        let _ativo = item.ativo == 0 ? 1 : 0;


        Promise.resolve(RequestPost({ post_id: item.post_id, ativo: _ativo, token: this.props.user.token }, "api/Posts/Ativo"))
            .then((data) => {
                let _list = [...this.state.items];
                _list[index].ativo = _ativo;
                this.setState({ items: _list });
            });
    }


    _GenerateAllPosts = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Posts/GenerateAllPosts"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    _GenerateAllCharacters = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Posts/GenerateAllCharacters"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    _GenerateAllBoxes = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Caixas/GenerateAllBoxes"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    _GenerateBanlist = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Posts/GenerateBanlist"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    _GenerateGuiaDecks = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Posts/GenerateGuiaDecks"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    render() {
        const { list } = this.state;

        return (
            <div className="Post">
                <div className="row">
                    <div className={'col col-lg-' + (this.props.user.name == 'Anti-puff'?'8':'12')}>
                        <div className="ibox">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2>Lista de Posts</h2>
                                        <p>
                                            A lista inclui todos posts ordenados por datas decrescentes.
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="input-group">
                                        <input id="word" name="word" type="text" placeholder="Buscar por nome" className="input form-control" value={this.state.word} onChange={this._handerWordChange} />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn btn-primary" onClick={() => this._Reload()}> <i className="fa fa-search"></i> Busca</button>
                                         </span>
                                     </div>
                                </div>

                                <div className="row">
                                    <br />
                                   
                                    <Tabs className="tabActiveLinkStyle">
                                        <TabLink to="tab1" className="tabLink">Posts</TabLink>


                                        <TabContent for="tab1" className="tabContent">
                                            <div className="table">
                                                <InfiniteScroll
                                                    dataLength={this.state.items.length}
                                                    next={this.fetchMoreData}
                                                    hasMore={this.state.hasMore}
                                                    loader={
                                                        <p style={{ textAlign: "center" }}>
                                                            <b>Carregando...</b>
                                                        </p>
                                                    }
                                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                            <b>Não há mais dados</b>
                                                        </p>
                                                    }>

                                                    {this.state.items.map((item, i) => (
                                                        <div key={'item' + i} className="tr">
                                                            <div className="td data" onClick={() => this._OnPostClick(item)}>{item.data}</div>
                                                            <div className="td title" onClick={() => this._OnPostClick(item)}>{item.titulo}</div>
                                                            <div className="td action"><a onClick={() => this._OnAtivoClick(item, i)}><span className={'label' + (item.ativo == 1 ? ' label-primary' : ' label-info')}>{item.ativo == 1 ? 'Ativo' : 'Inativo'}</span></a></div>
                                                            <div className="cleaner">&nbsp;</div>
                                                        </div>
                                                    ))}
                                                </InfiniteScroll>
                                            </div>


                                        </TabContent>
                                    </Tabs>
                                    

                                </div>
                            </div>
                        </div>

                        
                    </div>

                    {this.props.user.name != 'Anti-puff' ? '' : (
                        <div className="col col-lg-4">
                            <div className="ibox">
                                <div>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateAllPosts()}><i className="fa fa-check"></i> Gerar Todos Posts</button>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateAllCharacters()}><i className="fa fa-check"></i> Gerar Personagens</button>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateAllBoxes()}><i className="fa fa-check"></i> Gerar Caixas</button>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateBanlist()}><i className="fa fa-check"></i> Gerar Banlist</button>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateGuiaDecks()}><i className="fa fa-check"></i> Gerar Guia de Decks</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Posts);