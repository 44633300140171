import { RequestPost } from '../scripts/Request.js';
import {Globals } from '../scripts/Globals.js';

const requestAcessType = 'REQUEST_ACCESS';
const receiveAcessType = 'RECEIVE_ACCESS';
const receiveErrorType = 'ERROR_ACCESS'
const receiveLogoutType = 'LOGOUT_ACCESS'

let initialState = {
    user: {user_id:"", name:"", token: "", lastAccess:""}
};

export const actionCreators = {
    requestAccess: form => async (dispatch, getState) => {
        if (initialState.user.name === "") {
            console.log("fetching auth ...");
            dispatch({ type: requestAcessType, user: { user_id: "", name: "", token: "" } });
            Promise.resolve(RequestPost(form, "/api/Auth/Login"))
                .then((data) => {
                    initialState.user = data;
                    if (data.name != "") {
                        dispatch({ type: receiveAcessType, user: data });
                        Globals.Set("user", data);
                    } else {
                        dispatch({ type: receiveErrorType, user: data });
                    }
                });
        }
       
    },

    clearCredentials: () => (dispatch, getState) => {
        initialState = {
            user: { user_id: "", name: "", token: "", lastAccess: "" }
        };
        dispatch({ type: receiveLogoutType, user: { user_id: "", name: "", token: "", lastAccess: "" } });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestAcessType) {
        return { ...state, user: action.user, isLoading: true};
    }

    if (action.type === receiveAcessType) {
        return { ...state, user: action.user, isLoading: false, isError: false };
    }

    if (action.type === receiveErrorType) {
        return { ...state, user: action.user, isLoading: false, isError: true };
    }

    if (action.type === receiveLogoutType) {
        return { ...state, user: action.user, isLoading: false };
    }

    return state;
};
