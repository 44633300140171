/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Auth';
//import { LinkContainer} from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { RequestGet } from '../scripts/Request.js';
import $ from 'jquery';
import './NavMenu.css';

class NavMenu extends Component {
    constructor (props) {
        super(props);

        this.state = {
            list:[]
        };
    }


    componentWillMount() {
        if (this.state.list.length == 0 && this.props.user.name != "") {
            this.Init();
        }
    }


    componentDidUpdate() {
        
    }


    Init = async () => {
        await Promise.resolve(RequestGet("/json/menu.json"))
            .then((data) => {
                this.setState({list: data});
            });
    }


    _handleMenuClick = (item, index) => {
        for (var i = 0; i < this.state.list.length; i++) {
            if (i == index) {
                $("#item" + index).addClass("active");
                $("#subitems" + index).removeClass("collapse");
            } else {
                $("#item" + i).removeClass("active");
                $("#subitems" + i).addClass("collapse");
            }
        }
    }


    render() {
        const { list } = this.state;

        return (
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            <span>
                                <img alt="image" className="img-circle" src="img/profile_small.jpg" />
                            </span>
                            <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="clear">
                                    <span className="block m-t-xs">
                                        <strong className="font-bold">{this.props.user.name}</strong>
                                    </span>
                                </span>
                            </a>

                        </div>
                        <div className="logo-element">
                            DL
                        </div>
                    </li>


                    {list.map((item, i) => (
                        <li key={'item' + i} id={'item' + i}>
                            {item.link != "" ? (
                                <LinkContainer to={item.link} exact>
                                    <a><i className={item.icon}></i> <span className="nav-label">{item.name}</span> <span className="fa arrow"></span></a>
                                </LinkContainer>
                            ) : (
                                    <a onClick={() => this._handleMenuClick(item, i)}><i className={item.icon}></i> <span className="nav-label">{item.name}</span> <span className="fa arrow"></span></a>  
                            )}
                            <ul className="nav nav-second-level collapse" id={'subitems' + i}>
                                {item.nodes.map((subitem, j) => (
                                    <li key={'subitem' + j}>
                                        <LinkContainer to={subitem.link} exact>
                                            <a>{subitem.name}</a>
                                        </LinkContainer>
                                    </li>
                                ))}
                            </ul>  
                        </li>
                    ))}


                </ul>

            </div>
        );
    }
}


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NavMenu);
