/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { RequestPost } from '../../scripts/Request.js';
import FormGenerator from '../Form/FormGenerator';
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import './Caixa.css';


class Caixa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box_id: "",
            name: "",
            type: "",
            data: "",
            konami_link: "",
            descricao: "",
            cards: [],
            selected_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" },
            new_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" }, 
            search: { name: "" },
            results: [],
            isLoading: false,
            showModal: false
        }

        this._HandleSubmit = this._HandleSubmit.bind(this);
    }


    componentDidMount() {
       
    }

    componentWillMount() {
        let _box_id = this.props.match.params.boxId;
        this.Init(_box_id);
    }


    componentWillReceiveProps(nextProps) {
        let _box_id  = nextProps.match.params.boxId;
        this.Init(_box_id);
    }


    Init = async (box_id) => {
        if (box_id != "" && box_id != null && box_id != undefined) {
            this.setState({ isLoading: true });
            Promise.resolve(RequestPost({ token: this.props.user.token}, "api/Caixas/Load?box_id=" + box_id))
                .then((data) => {
                    this.setState({
                        box_id: box_id,
                        name: data.name,
                        type: data.type,
                        data: data.data,
                        konami_link: data.konami_link,
                        descricao: data.descricao,
                        cards: data.cards,
                        isLoading: false,
                        showModal: false
                    })

                    this._formCaixaRef.Load(data);
                    //Globals.Run("form_caixa", "Load", data);
                });
        }
    }


    //#region Form Functions
    sub_name = (args) => {
       
    }
    //#endregion Form Functions


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }

        if (event.target.id == "header") {
            this.Init(event.target.value);
        }
    }


    _HandleRarityChange = (event) => {
        let _cards = this.state.cards;
        cards.find(c => c.card_id == event.target.id).rarity = event.target.value;
        this.setState({ selected_card: _cards.find(c => c.card_id == event.target.id) });
        this.setState({ cards: _cards });

        setTimeout(() => { this._SaveCard(); }, 300);
    }

    _HandlePropertyChange = (event) => {
        let _card = this.state.selected_card;
        _card[event.target.id] = event.target.value;
        this.setState({ selected_card: _card });
    }


    _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        console.log("Post " + formname);
        this.setState({ isLoading: true });
        let _result = await this._formCaixaRef.Post("api/Caixas/Save", { box_id: this.state.box_id, token: this.props.user.token });
        this.setState({ isLoading: false });

        /*let wait_result = setInterval(() => {
            console.log(_result);
            if (_result != null && _result != undefined && _result != "") {
                clearInterval(wait_result);
                this.setState({ isLoading: false });
            }
        }, 300);*/
    }

    OnPost = (data) => {
        console.log("OnPost", data);
    }


    _OnCardClick = (card) => {
        this.setState({ selected_card: card });
    }


    _SaveCard = () => {
        let _card = Object.assign(this.state.selected_card, { token: this.props.user.token });
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Caixas/SaveCard"))
            .then((data) => {
                this.setState({
                    selected_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" }
                });
                this.setState({ isLoading: false });
            });
    }


    _OnMetaChange = (card, event) => {
        let _cards = this.state.cards;
        _cards.find(c => c.card_id == card.card_id).ismeta = event.target.checked;
        let _card = Object.assign(card, { token: this.props.user.token });

        _card.ismeta = event.target.checked ? 1 : 0;
        console.log(card.ismeta, event.target.value);

        this.setState({ cards: _cards });

        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Caixas/SaveMeta"))
            .then((data) => {
                this.setState({ isLoading: false, cards: _cards });
            });
    }


    _Remove = (card) => {
        let _card = Object.assign(card, { token: this.props.user.token, box_id: this.state.box_id });
        let _cards = this.state.cards;
        let index = _cards.findIndex(c => c.card_id == _card.card_id);
        _cards.splice(index, 1);

        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Caixas/RemoveCard"))
            .then((data) => {
                this.setState({ cards: _cards, isLoading: false });
            });
    }



    //#region Modal
    _handleOpenModal = () => {
        this.setState({ showModal: true });
    }


    _handleCloseModal = () => {
        this.setState({ showModal: false });
    }


    _handleSaveModal = () => {
        let _card = Object.assign(this.state.new_card, { token: this.props.user.token, box_id: this.state.box_id });
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_card, "api/Caixas/SaveBoxCard"))
            .then((data) => {
                let _cards = this.state.cards;
                _cards.push(this.state.new_card);
                this.setState({
                    cards: _cards,
                    new_card: { image: "", name: "", name_br: "", rarity: "", description: "", description_br: "", types: "", types_br: "", attribute: "", attribute_br: "", level: "", atk: "", def: "", isextra: "", limit: "", code: "", card_id: "", ismeta: "" },
                    isLoading: false,
                    showModal: false
                });

            });
    }


    _OnCloseModal = () => {

    }
    //#endregion Modal



    //#region Search Card 
    _search_timer_change = null;
    _searching_busy = false;
    _OnSearchCardChange = (event) => {
        let _search = this.state.search;
        _search.name = event.target.value;
        this.setState({ search: _search });

        clearInterval(this._search_timer_change);

        if (event.target.value.indexOf(". ") > -1) {
            clearInterval(this._search_timer_change);
            this._OnSearchCardTimerChange(event.target.value);
        } else {
            this.setState({ results: [] });
            this._search_timer_change = setTimeout(() => {
                this._OnSearchCardTimerChange(_search.name);
            }, 600);
        }
    }


    _OnSearchCardTimerChange = (word) => {
        if (word.indexOf(". ") > -1 && this.state.results.length>0) {
            let _card_id = eval(word.split(".")[0]);
            let _card = this.state.results.find(c => c.card_id == _card_id);
            this.setState({ new_card: _card });
        } else {
            if (word.length > 2 && !this._searching_busy) {
                this._searching_busy = true;
                Promise.resolve(RequestPost({ word: word }, "api/Caixas/CardList"))
                    .then((data) => {
                        this._searching_busy = false;
                        this.setState({ results: data });
                    });
            }
        }
    }


    _OnSearchCardBlur = () => {

    }



    _HandleNewPropertyChange = (event) => {
        let _card = this.state.new_card;
        _card[event.target.id] = event.target.value;
        this.setState({new_card: _card });
    }


    _OnTextClick = (event) => {
        event.target.setSelectionRange(0, event.target.value.length);
        
    }
    //#region Search Card 


    _SaveNewCard = () => {

    }
  



    render() {
        let { cards, search, new_card, results } = this.state;
        let _ur = cards.filter(item => { return item.rarity == 'ur' });
        let _sr = cards.filter(item => { return item.rarity == 'sr' });
        let _r = cards.filter(item => { return item.rarity == 'r' });
        let _n = cards.filter(item => { return item.rarity == 'n' });
        let _none = cards.filter(item => { return item.rarity == '' });

        return (
            <div className="Caixa">
                <LoadingOverlay>
                    <div className="row">
                        <form name="fcaixa" onSubmit={(event) => this._HandleSubmit('caixa', event)} autoComplete="off">
                            <div className="col-lg-8">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <h5>Cadastro de Caixa</h5>
                                        <label className="label label-default pull-right btn" onClick={this._handleOpenModal}> Adicionar carta </label>
                                    </div>
                                    <div className="ibox-content ibox-heading">
                                        <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                    </div>

                                    <div className="ibox-content">
                                        <div className="row m-b-lg">
                                            <FormGenerator ref={instance => { this._formCaixaRef = instance; }} name={'caixa'} url={'/forms/caixa.json'} origin={this}></FormGenerator>
                                            <hr />
                                            <button className="btn btn-white btn-sm" title="Salvar" type="submit" ><i className="fa fa-user"></i> Salvar Caixa</button>
                                        </div>

                                        <div style={{ padding: '30px' }}>
                                            <div>
                                                <img src="/img/deckbuilder/rare-ur.png" alt="" />
                                            </div>

                                            {_ur.map((item, i) => (
                                                <div key={'ur'+i} className="pcard">
                                                    <select id={item.card_id} value={item.rarity} onChange={this._HandleRarityChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR</option>
                                                        <option value="sr">SR</option>
                                                        <option value="r">R</option>
                                                        <option value="n">N</option>
                                                    </select>
                                                    <div id={'exdeck_cardA' + i} className="ccard" onClick={() => this._OnCardClick(item)}>
                                                        <div className="imgCard">
                                                            <img className={'cardimg' + (item.image != ''?'':' hide')} src={'https://images.weserv.nl/?w=138&h=200&url=' + item.image + '&temp'} width="138" height="200" alt={item.name_br} />
                                                            <img className={'limit1img' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 4 ? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <div>
                                                            <input type="checkbox" className="icheck" checked={item.ismeta} onChange={(event) => this._OnMetaChange(item, event)} />
                                                            <a onClick={() => this._Remove(item)}> Remover </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <hr />
                                            <div>
                                                <img src="/img/deckbuilder/rare-sr.png" alt="" />
                                            </div>

                                            {_sr.map((item, i) => (
                                                <div key={'sr' + i} className="pcard">
                                                    <select id={item.card_id} value={item.rarity} onChange={this._HandleRarityChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR</option>
                                                        <option value="sr">SR</option>
                                                        <option value="r">R</option>
                                                        <option value="n">N</option>
                                                    </select>
                                                    <div id={'exdeck_cardA' + i} className="ccard" onClick={() => this._OnCardClick(item)}>
                                                        <div className="imgCard">
                                                            <img className={'cardimg' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=138&h=200&url=' + item.image + '&temp'} width="138" height="200" alt={item.name_br} />
                                                            <img className={'limit1img' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 4 ? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <div>
                                                            <input type="checkbox" className="icheck" checked={item.ismeta} onChange={(event) => this._OnMetaChange(item, event)} />
                                                            <a onClick={() =>this._Remove(item) }> Remover </a>
                                                         </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <hr />
                                            <div>
                                                <img src="/img/deckbuilder/rare-r.png" alt="" />
                                            </div>

                                            {_r.map((item, i) => (
                                                <div key={'r' + i} className="pcard">
                                                    <select id={item.card_id} value={item.rarity} onChange={this._HandleRarityChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR</option>
                                                        <option value="sr">SR</option>
                                                        <option value="r">R</option>
                                                        <option value="n">N</option>
                                                    </select>
                                                    <div id={'exdeck_cardA' + i} className="ccard" onClick={() => this._OnCardClick(item)}>
                                                        <div className="imgCard">
                                                            <img className={'cardimg' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=138&h=200&url=' + item.image + '&temp'} width="138" height="200" alt={item.name_br} />
                                                            <img className={'limit1img' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 4 ? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <div>
                                                            <input type="checkbox" className="icheck" checked={item.ismeta} onChange={(event) => this._OnMetaChange(item, event)} />
                                                            <a onClick={() => this._Remove(item)}> Remover </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <hr />
                                            <div>
                                                <img src="/img/deckbuilder/rare-n.png" alt="" />
                                            </div>

                                            {_n.map((item, i) => (
                                                <div key={'n' + i} className="pcard">
                                                    <select id={item.card_id} value={item.rarity} onChange={this._HandleRarityChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR</option>
                                                        <option value="sr">SR</option>
                                                        <option value="r">R</option>
                                                        <option value="n">N</option>
                                                    </select>
                                                    <div id={'exdeck_cardA' + i} className="ccard" onClick={() => this._OnCardClick(item)}>
                                                        <div className="imgCard">
                                                            <img className={'cardimg' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=138&h=200&url=' + item.image + '&temp'} width="138" height="200" alt={item.name_br} />
                                                            <img className={'limit1img' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 4 ? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <div>
                                                            <input type="checkbox" className="icheck" checked={item.ismeta} onChange={(event) => this._OnMetaChange(item, event)} />
                                                            <a onClick={() => this._Remove(item)}> Remover </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                            <hr />
                                            <div>
                                                <img src="/img/deckbuilder/rare-none.png" alt="" />
                                            </div>

                                            {_none.map((item, i) => (
                                                <div key={'no' + i} className="pcard">
                                                    <select id={item.card_id} value={item.rarity} onChange={this._HandleRarityChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR</option>
                                                        <option value="sr">SR</option>
                                                        <option value="r">R</option>
                                                        <option value="n">N</option>
                                                    </select>
                                                    <div id={'exdeck_cardA' + i} className="ccard" onClick={() => this._OnCardClick(item)}>
                                                        <div className="imgCard">
                                                            <img className={'cardimg' + (item.image != '' ? '' : ' hide')} src={'https://images.weserv.nl/?w=138&h=200&url=' + item.image + '&temp'} width="138" height="200" alt={item.name_br} />
                                                            <img className={'limit1img' + (item.limit == 1 ? '' : ' hide')} src="/img/deckbuilder/limit1.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 2 ? '' : ' hide')} src="/img/deckbuilder/limit2.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 3 ? '' : ' hide')} src="/img/deckbuilder/limit3.png" width="23" height="23" />
                                                            <img className={'limit1img' + (item.limit == 4 ? '' : ' hide')} src="/img/deckbuilder/ban.png" width="23" height="23" />
                                                        </div>
                                                        <div>
                                                            <input type="checkbox" className="icheck" checked={item.ismeta} onChange={() => this._OnMetaChange(item)} />
                                                            <a onClick={() => this._Remove(item)}> Remover </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-content" id="card_info">
                                        <div className="row" style={{ height: '200px' }}>
                                            <center>
                                                <img src={'https://images.weserv.nl/?w=138&h=200&url=' + this.state.selected_card.image + '&temp'} width="138" height="200" alt={this.state.selected_card.name_br} ng-show="item.image!=''" />
                                            </center>
                                        </div>

                                        <div className="row">
                                            <ul className="list-group clear-list">
                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Raridade </span>
                                                    <select id="rarity" className="form-control" value={this.state.selected_card.rarity} onChange={this._HandlePropertyChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR - Ultra Rara</option>
                                                        <option value="sr">SR - Super Rara</option>
                                                        <option value="r">R - Rara</option>
                                                        <option value="n">N - Normal</option>
                                                    </select>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Imagem da carta <sup>não use o images.weserv.nl</sup></span>
                                                    <input id="image" type="text" className="form-control" value={this.state.selected_card.image} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em ingles </span>
                                                    <input id="name" type="text" className="form-control" value={this.state.selected_card.name} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em português </span>
                                                    <input id="name_br" type="text" className="form-control" value={this.state.selected_card.name_br} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição em ingles </span>
                                                    <textarea id="description" className="form-control" style={{height:'150px'}} value={this.state.selected_card.description} onChange={this._HandlePropertyChange}>{this.state.selected_card.description}</textarea>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição português </span>
                                                    <textarea id="description_br" className="form-control" style={{ height: '150px' }} value={this.state.selected_card.description_br} onChange={this._HandlePropertyChange}>{this.state.selected_card.description_br}</textarea>
                                                </li>


                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em ingles </span>
                                                    <input id="types" type="text" className="form-control" value={this.state.selected_card.types} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em português </span>
                                                    <input id="types_br" type="text" className="form-control" value={this.state.selected_card.types_br} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em ingles </span>
                                                    <input id="attribute" type="text" className="form-control" value={this.state.selected_card.attribute} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em português </span>
                                                    <input id="attribute_br" type="text" className="form-control" value={this.state.selected_card.attribute_br} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nivel </span>
                                                    <input id="level" type="text" className="form-control" value={this.state.selected_card.level} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Ataque </span>
                                                    <input id="atk" type="text" className="form-control" value={this.state.selected_card.atk} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Def/link </span>
                                                    <input id="def" type="text" className="form-control" value={this.state.selected_card.def} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> É um monstro de extra deck (1 - sim, 0 - não)</span>
                                                    <input id="isextra" type="text" className="form-control" value={this.state.selected_card.isextra} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Limitação (use 4 para banido)</span>
                                                    <input id="limit" type="text" className="form-control" value={this.state.selected_card.limit} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Duellinks) </span>
                                                    <input id="code" type="text" className="form-control" value={this.state.selected_card.code} onChange={this._HandlePropertyChange} />
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Banco Local) </span>
                                                    <input id="card_id" type="text" className="form-control" value={this.state.selected_card.card_id} onChange={this._HandlePropertyChange} />
                                                </li>
                                            </ul>

                                            <br />
                                            <button className="btn btn-white btn-sm" title="Salva Carta" type="button" disabled={!(this.state.selected_card.card_id != '' && this.state.selected_card.card_id != undefined && this.state.selected_card.card_id != null)} onClick={() => this._SaveCard()}><i className="fa fa-check"></i> Salvar Carta</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Modal show={this.state.showModal} onHide={this._handleCloseModal} animation={false} dialogClassName="modal900">
                        <Modal.Header>
                            <Modal.Title> Inserir nova carta </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '250px' }} valign="top">
                                            <center>
                                                <img src={'https://images.weserv.nl/?w=138&h=200&url=' + new_card.image + '&temp'} width="138" height="200" alt={new_card.name_br} className={new_card.image != '' ? '' : ' hide'} />
                                            </center>
                                        </td>

                                        <td>
                                            <input id="search" autoComplete="off" type="text" className="form-control" onClick={this._OnTextClick} list="estuff" value={search.name} onChange={this._OnSearchCardChange} onBlur={this._OnSearchCardBlur} />
                                            <datalist id="estuff">
                                                {results.map((item, i) => (
                                                    <option key={'dl' + i} value={item.card_id + '. ' + item.name_br}>{item.name}</option>
                                                ))}
                                            </datalist>
                                            <hr />


                                            <ul className="list-group clear-list">
                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Raridade </span>
                                                    <select id="rarity" className="form-control" value={new_card.rarity} onChange={this._HandleNewPropertyChange}>
                                                        <option value="">Escolha uma raridade</option>
                                                        <option value="ur">UR - Ultra Rara</option>
                                                        <option value="sr">SR - Super Rara</option>
                                                        <option value="r">R - Rara</option>
                                                        <option value="n">N - Normal</option>
                                                    </select>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Imagem da carta <sup>não use o images.weserv.nl</sup></span>
                                                    <input id="image" type="text" value={new_card.image} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em ingles </span>
                                                    <input id="name" type="text" value={new_card.name} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nome em português </span>
                                                    <input id="name_br" type="text" value={new_card.name_br} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição em ingles </span>
                                                    <textarea id="description" value={new_card.description} className="form-control" style={{ height: '200px' }} onChange={this._HandleNewPropertyChange}></textarea>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Descrição português </span>
                                                    <textarea id="description_br" value={new_card.description_br} className="form-control" style={{ height: '200px' }} onChange={this._HandleNewPropertyChange}></textarea>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em ingles </span>
                                                    <input id="types" type="text" value={new_card.types} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Tipo em português </span>
                                                    <input id="types_br" type="text" value={new_card.types_br} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em ingles </span>
                                                    <input id="attribute" type="text" value={new_card.attribute} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Atributo em português </span>
                                                    <input id="attribute_br" type="text" value={new_card.attribute_br} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Nivel </span>
                                                    <input id="level" type="text" value={new_card.level} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Ataque </span>
                                                    <input id="atk" type="text" value={new_card.atk} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Def </span>
                                                    <input id="def" type="text" value={new_card.def} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> É um monstro de extra deck (1 - sim, 0 - não)</span>
                                                    <input id="isextra" type="text" value={new_card.isextra} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Limitação </span>
                                                    <input id="limit" type="text" value={new_card.limit} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Duellinks) </span>
                                                    <input id="code" type="text" value={new_card.code} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>

                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Código (Banco Local) </span>
                                                    <input id="card_id" type="text" value={new_card.card_id} className="form-control" onChange={this._HandleNewPropertyChange}/>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this._handleCloseModal}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={this._handleSaveModal}>
                                Salvar Carta
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Caixa);