/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { UploadService } from '../../scripts/UploadService';
import { Globals } from '../../scripts/Globals.js';
import moment from 'moment';
import $ from 'jquery';
import './Page.css';


class Page extends Component {
    domain = "https://dlbrasiladmin.codemill.com.br";

    constructor(props) {
        super(props);
        this.state = {
            page_id: "",
            html: "",
            header: "",
            titulo: "",
            hasside: false,
            isLoading: false
        };
    }



    async componentDidMount() {
        let gArrayFonts = ["open sans", "Arial Black", "Comic Sans MS", "Courier New", "Helvetica", "Impact", "Tahoma", "Times New Roman", "Verdana"];

        let self = this;

        window.$("#summernote").summernote({
            fontNames: gArrayFonts,
            fontNamesIgnoreCheck: gArrayFonts,
            height: 400,
            callbacks: {
                onImageUpload: function (files, editor, welEditable) {
                    self.img_place = "summernote";
                    self.SendFile(files[0], editor, welEditable);
                },
                onChange: function (contents, $editable) {
                    self.OnHtmlChange();
                }
            }
        });
    }


    componentWillMount() {
    
    }


    componentWillReceiveProps(nextProps) {
       
    }


    Init = (header) => {
        if (header != "" && header != null && header != undefined) {
            this.setState({ isLoading: true });
            Promise.resolve(RequestPost({token: this.props.user.token}, "api/Page/Load?header=" + header))
                .then((data) => {
                    window.$("#summernote").summernote('code', data.html);

                    this.setState({
                        page_id: data.page_id,
                        html: data.html,
                        titulo: data.titulo,
                        header: data.header,
                        hasside: data.hasside == 0,
                        isLoading: false
                    })
                });
        }
    }


    SendFile = (file, editor, welEditable) => {
        let uploadService = new UploadService();
        uploadService.Queue(file);

        uploadService.Start("api/Page/Upload", (result) => {
            //OnProgress
            console.log("OnProgress", result.percent);
        }, (result) => {
            //OnEnd
            console.log("OnEnd", result);
            window.$('#summernote').summernote('insertImage', this.domain + "/db/" + result.img, function ($image) {
                $image.css('width', $image.width() / 2);
                $image.attr('data-filename', 'retriever');
            });
        });
    }


    OnHtmlChange = () => {
        var aHTML = window.$('#summernote').summernote('code');
        this.setState({ html: aHTML });
    }


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }

        if (event.target.id == "header") {
            this.Init(event.target.value);
        }
    }


    _OnSaveClick = () => {
        var aHTML = window.$('#summernote').summernote('code');
        this.setState({ html: aHTML });

        if (aHTML != "" && this.state.data != "" && this.state.header != "") {
            this.setState({ isLoading: true });
            Promise.resolve(RequestPost({ page_id: this.state.page_id, html: aHTML, data: moment(this.state.data).format('YYYY-MM-DD'), titulo: this.state.titulo, header: this.state.header, hasside: (!this.state.hasside ? 1 : 0), token: this.props.user.token }, "api/Page/Save"))
                .then((data) => {
                    if (data == "loaded") {
                        this.setState({ isLoading: false });
                    }
                });
        }
    }


    _GenerateAllPages = () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Posts/GenerateAllPages"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }
   


    render() {
        return (
            <div className="Page">
                <LoadingOverlay>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Editor de Texto</h5>
                                    <div className="ibox-tools">
                                        <select id="header" className="form-control pull-right" onChange={this._HandlerChange}>
                                            <option value="">Escolha uma página</option>
                                            <option value="guia">Guia de Iniciantes</option>
                                            <option value="sparrowcup">Sparrow Cup</option>
                                            <option value="personagens">Personagens</option>
                                            <option value="apoiase">Apoia-se</option>
                                            <option value="gdecks">Guia de Decks</option>
                                            <option value="banlist">Banlist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="ibox-content no-padding">
                                    <div id="summernote">

                                    </div>
                
                                    <hr />
                                    <div className="pad5">
                                        <input id="hasside" className="chk_hasside" type="checkbox" checked={this.state.hasside} onChange={this._HandlerChange} /> Post de página inteira<br />
                                        <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._OnSaveClick()} disabled={(this.state.html == "" || this.state.data == "" || this.state.header == "")}><i className="fa fa-check"></i> Salvar Post</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="ibox float-e-margins">
                                <div className={(this.props.user.name == 'Anti-puff') ? '' : 'hide'}>
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._GenerateAllPages()}><i className="fa fa-check"></i> Gerar Todas Paginas</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Page);