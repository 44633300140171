/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-overlay-loader/styles.css';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { UploadService } from '../../scripts/UploadService';
import moment from 'moment';
import $ from 'jquery';
import './Post.css';



class Post extends Component {
    domain = "https://dlbrasiladmin.codemill.com.br";
    page_gallery = 0;
    offset_gallery = 0;

    constructor(props) {
        super(props);
        this.state = {
            post_id: "",
            html: "",
            data: "",
            titulo: "",
            img_capa: "",
            categoria: "0",
            url: "",
            hasside: true,
            isLoading: false,
            gallery: [],
            hasMore_gallery: true,
            total_gallery: 300,
        };
    }

    



    async componentDidMount() {
        let gArrayFonts = ["open sans", "Arial Black", "Comic Sans MS", "Courier New", "Helvetica", "Impact", "Tahoma", "Times New Roman", "Verdana"];

        let self = this;

        window.$("#summernote").summernote({
            fontNames: gArrayFonts,
            fontNamesIgnoreCheck: gArrayFonts,
            height: 400,
            callbacks: {
                onImageUpload: function (files, editor, welEditable) {
                    self.img_place = "summernote";
                    self.SendFile(files[0], editor, welEditable);
                },
                onChange: function (contents, $editable) {
                    self.OnHtmlChange();
                }
            }
        });
    }


    componentWillMount() {
        let _post_id = this.props.match.params.postId;
        this.Init(_post_id);

        this.page_gallery = 0;
        this.offset_gallery = 0;
        this.InitGallary();
    }


    componentWillReceiveProps(nextProps) {
        let _post_id = nextProps.match.params.postId;
        this.Init(_post_id);
    }


    Init = async (post_id) => {
        if (post_id != "" && post_id != null && post_id != undefined) {
            Promise.resolve(new RequestGet("api/Posts/Load?post_id=" + post_id))
                .then((data) => {
                    window.$("#summernote").summernote('code', data.html);
                    let d = data.data.split("/");

                    this.setState({
                        post_id: post_id,
                        html: data.html,
                        data: (d[2] + "-" + d[1] + "-" + d[0]),
                        titulo: data.titulo,
                        img_capa: (data.capa.indexOf("/db/") > -1 ? data.capa : "/db/" + data.capa),
                        categoria: data.categoria_id,
                        url: data.url,
                        hasside: data.hasside == 0
                    })
                });
        }
    }


    SendFile = (file, editor, welEditable) => {
        let uploadService = new UploadService();
        uploadService.Queue(file);

        uploadService.Start("api/Posts/Upload", (result) => {
            //OnProgress
            console.log("OnProgress", result.percent);
        }, (result) => {
            //OnEnd
            console.log("OnEnd", result);
            window.$('#summernote').summernote('insertImage', this.domain + "/db/" + result.img, function ($image) {
                $image.css('width', $image.width() / 2);
                $image.attr('data-filename', 'retriever');
            });
        });
    }


    OnHtmlChange = () => {
        var aHTML = window.$('#summernote').summernote('code');
        this.setState({ html: aHTML });
    }


    InitGallary = () => {
        Promise.resolve(RequestPost({ word: this.state.word }, "api/Gallery/List?page=" + this.page_gallery + "&interval=50&offset=" + this.offset_gallery))
            .then((data) => {
                this.state.total_gallery = data.total;

                this.setState({
                    total_gallery: data.total,
                    gallery: this.state.gallery.concat(data.list)
                });
            });
    }


    fetchMoreDataGallery = () => {
        if (this.state.gallery.length >= this.state.total_gallery) {
            this.setState({ hasMore_gallery: false });
            return;
        }

        this.page_gallery++;
        this.offset_gallery++;
        Promise.resolve(RequestPost({ word: this.state.word }, "api/Gallery/List?page=" + this.page_gallery + "&interval=50&offset=" + this.offset_gallery))
            .then((data) => {
                this.setState({
                    gallery: this.state.gallery.concat(data.list)
                })
            });
    };


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }
    }


    _UploadImages = (selector) => {
        document.getElementById(selector).click();
        $(document.getElementById(selector)).bind('change', (e) => {
            this.setState({ isLoading: true });
            let arr = document.getElementById(selector).files;

            let uploadService = new UploadService();
            for (let i = 0; i < arr.length; i++) {
                uploadService.Queue(arr[i]);
            }
            uploadService.Start("api/Posts/Upload", (result) => {
                    //OnProgress
                    console.log("OnProgress", result.percent);
            }, (result) => {
                    //OnEnd
                    console.log("OnEnd", result);
                    this.setState({ isLoading: false, img_capa: this.domain +"/db/" + result.img });
            });
        });
    }


    



    _OnSaveClick = () => {
        var aHTML = window.$('#summernote').summernote('code');
        this.setState({ html: aHTML });
        //console.log(aHTML, this.state.data, this.state.titulo, this.state.img_capa, this.state.categoria)

        if (aHTML != "" && this.state.data != "" && this.state.titulo != "" && this.state.img_capa != "" && this.state.categoria != "") {
            this.setState({ isLoading: true });

            let _capa = this.state.img_capa;
            if (_capa.indexOf("http") == -1) _capa = _capa.replace("/db/", "")
            Promise.resolve(RequestPost({ post_id: this.state.post_id, html: aHTML, data: moment(this.state.data).format('YYYY-MM-DD'), titulo: this.state.titulo, capa: _capa, categoria_id: this.state.categoria, url: this.state.url, hasside: (!this.state.hasside ? 1 : 0), token: this.props.user.token }, "api/Posts/Save"))
                .then((data) => {
                    if (data == "loaded") {
                        this.setState({ isLoading: false });
                        this.props.history.push("/Posts");
                    }
                });
        }
    }


    _GalleryClick = (item) => {
        this.setState({ img_capa: this.domain + "/db/" + item });
        console.log(item);
    }
   


    render() {

        return (
            <div className="Post">
                <LoadingOverlay>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox-title">
                                <h5>Editor de Texto</h5>
                                <div className="ibox-tools">
                                    <input id="data" type="date" className="input150 form-control pull-right" value={this.state.data} onChange={this._HandlerChange} />
                                    <select id="categoria" className="input150 form-control pull-right" value={this.state.categoria} onChange={this._HandlerChange} >
                                        <option value="0">Post</option>
                                        <option value="1">Datamine</option>
                                        <option value="2">Personagens</option>
                                        <option value="3">Decks</option>
                                        <option value="4">Torneios</option>
                                    </select>
                                    <input type="text" id="titulo" className="input300 form-control pull-right" placeholder="Título do Post" value={this.state.titulo} onChange={this._HandlerChange} />
                                </div>
                            </div>

                            <div className="ibox-content">
                                <div id="summernote">
                              
                                </div>
                                <hr />
                                <div className="pad5">
                                    <input id="url" type="text" className="input350 form-control pull-right" placeholder="Url Externo" value={this.state.url} onChange={this._HandlerChange} />
                                    <input id="hasside" className="chk_hasside" type="checkbox" checked={this.state.hasside} onChange={this._HandlerChange} /> Post de página inteira<br />
                                    <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" type="button" onClick={() => this._OnSaveClick()} disabled={(this.state.html == "" || this.state.data == "" || this.state.titulo == "" || this.state.img_capa == "" || this.state.categoria == "")}><i className="fa fa-check"></i> Salvar Post</button>
                                </div>

                            </div>
                       
                        </div>


                        <div className="col-lg-4">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content no-padding sidepane">
                                    <center>
                                        <input id="uploadfiles" name="uploadfiles" className="hide" type="file" accept="image/*" />
                                        <button className="form-control pull-right" onClick={() => this._UploadImages('uploadfiles')}>Capa</button>
                                    </center>
                                    <hr />

                                    <img src={this.state.img_capa} className={'capa' + (this.state.img_capa != '' ? '' : ' hide') } />
                                </div>
                            </div>
                            <br />

                            <div className="ibox float-e-margins">
                                <div className="ibox-content no-padding sidepane">
                                    <InfiniteScroll
                                        dataLength={this.state.gallery.length}
                                        next={this.fetchMoreDataGallery}
                                        hasMore={this.state.hasMore_gallery}
                                        loader={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Carregando...</b>
                                            </p>
                                        }
                                        _style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Não há mais imagens</b>
                                            </p>
                                        }>

                                        <div>
                                            {this.state.gallery.map((item, i) => (
                                                <div key={'item' + i} className="post_item6 pull-left">
                                                    <div className="nk-news-box-item-img item-post-img">
                                                        <a onClick={() => this._GalleryClick(item)}>
                                                            <img src={'https://images.weserv.nl/?w=100&h=100&url=https://dlbrasiladmin.codemill.com.br/db/' + item + '&fit=outside&a=top&temp'} />
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="clear"></div>
                                        </div>

                                        
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Post);