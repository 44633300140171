/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { Globals } from '../../scripts/Globals.js';
import InfiniteScroll from "react-infinite-scroll-component";
import $ from 'jquery';
import moment from 'moment';
import './FormGenerator.css';


class FormGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
        };
    }

    componentDidMount() {
        Globals.AddComponent("form_"+ this.props.name, this);
    }

    componentWillMount() {
        //console.log(this.props.name, this.props.url);
        this.Init();
    }


    Init = () => {
        Promise.resolve(new RequestGet(this.props.url))
            .then((data) => {
                data.map((item, i) => {
                    item.value = this.props.origin.state[item.name];// = event.target.id;
                })
                this.setState({ fields: data });

            });
    }


    Load = (data) => {
        let wait_form = setInterval(() => {
            if (this.state.fields.length > 0) {
                clearInterval(wait_form);
                let _fields = [...this.state.fields];
                _fields.map((field, i) => {
                    field.value = data[field.name];
                });
                this.setState({ fields: _fields });
            }
        }, 300);
    }


    Post = async (url, args) => {
        let _fields = this.PreProcFields(this.state.fields);
        _fields = (args != null) ? Object.assign(_fields, args) : _fields;

        return await Promise.resolve(RequestPost(_fields, url))
            .then((data) => {
                //Globals.Run("form_" + this.props.name, "OnPost", data);
                return data;
            });
    }


    PreProcFields = (fields) => {
        let obj = {};
        fields.map(item => {
            obj[item.name] = item.value;
        });
        return obj;
    }


    _HandleChange = (event) => {
        //console.log(event.target.id, event.target.value);

        let _fields = [...this.state.fields];
        let _field = _fields.find(f => f.name == event.target.id);

        if (event.target.type == "checkbox") {
            _field.checked = event.target.checked;
            this.setState({ fields: _fields });
            this._FieldFunction(event.target.id, event.target.checked);
        } else {
            _field.value = event.target.value;
            this.setState({ fields: _fields });
            this._FieldFunction(event.target.id, event.target.value);
        }
    }

    _HandleCurrencyChange = (event) => {
        let _fields = [...this.state.fields];
        let _field = _fields.find(f => f.name == event.target.id);

        var so = 0;
        var o = event.target.value.toString();
        if (o != null && o != undefined) {
            if (o.indexOf("$") > -1) {
                o = o.replace("R$", "");
                o = o.replace(" ", "");
                o = o.replace(",", "");
                o = o.replace(".", "");
                so = eval(o / 100);
            } else {
                so = eval(o / 100);
            }
        }

        _field.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(so);
        this.setState({ fields: _fields });
        this._FieldFunction(event.target.id, event.target.value);
    }


    _FieldFunction = (func, field) => {
        try {
            this.props.origin[func]();
        } catch (e) {}
    }



    _FieldComponent = (field) => {
        switch (field.type.view) {
            case 'separator':
                return (
                    <div>
                        <hr className={field.name} />
                    </div>
                )
            case 'separatorbar':
                return (
                    <div className="ibox-content ibox-heading" style={{ padding:'10px', fontSize:'14px' }}>
                        <small><i className="fa fa-stack-exchange"></i> {field.title}</small>
                    </div>
                )
            case 'input':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>
                )
            case 'currency':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="text" required={field.required} value={field.value} onChange={this._HandleCurrencyChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} mask={field.mask} placeholder={field.placeholder} />
                    </div>
                )

            case 'number':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="number" required={field.required} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'date':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="date" required={field.required} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'datetime':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="datetime-local" required={field.required} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'password':
                return (
                    <div>
                        <input id={field.name} name={field.name} className={'form-control fg-input ' + field.name} type="password" required={field.required} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)} />
                    </div>
                )

            case 'checkbox':
                return (
                    <div>
                        <input id={field.name} name={field.name} style={{ width: '25px', height: '25px'}} className={'form-control fg-input ' + field.name} type="checkbox" required={field.required} checked={field.value} onChange={this._HandleChange} disabled={field.disabled} />
                    </div>
                )

            case 'textarea':
                return (
                    <div>
                        <textarea id={field.name} name={field.name} style={{ height: (field.height||'100px') }}  className={'form-control fg-input ' + field.name} value={field.value} onChange={this._HandleChange} disabled={field.disabled} onBlur={() => this._FieldFunction('blur_' + field.name, field.value)}>{field.value}</textarea>
                    </div>
                )

            case 'select':
                return (
                    <div className="select">
                        <select id={field.name} name={field.name} className={'form-control fg-input ' + field.name} value={field.value} onChange={this._HandleChange} disabled={field.disabled} required={field.required}>
                            {field.type.options.map((option, i) => (
                                <option key={'opt_' + i} value={option.id} selected={option.selected==true}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                )

            case 'select_input':
                return (
                    <div className="select">
                        <table border="0" cellpadding="0" cellspacing="0" style={{ width:'100%', margin:0, padding:0 }}>
                            <tr style={{ margin: 0, padding: 0 }}>
                                <td style={{ width: 'auto', margin: 0, padding: 0 }} width="88%">
                                    <select id={field.name} name={field.name} style={{ width: '100%', margin: 0, padding: 0 }} className={'form-control fg-input ' + field.name} aria-label={field.name} aria-describedby="basic-addon2" value={field.value} disabled={field.disabled} onChange={this._HandleChange} required={field.required}>
                                        {field.type.options.map((option, i) => (
                                            <option key={'opti_'+i} value={option.id} selected={option.selected == true}>{option.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td style={{ width: 'auto', margin: 0, padding: 0 }} width="12%">
                                    <button className="btn btn-white" style={{ margin: 0, padding: 0 }} type="button" onClick={() => this._FieldFunction('new_' + field.name, field.value)}>Novo</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
        }
    }


    _InlineComponent = (field) => {
        if (field.inline == null || field.inline == undefined || field.inline == "" || field.inline == false) return (
            <div style={{ height: (field.height || (field.type.view=='textarea'?'100px':'')) }} >
                <label className={'fg-label' + (field.type.view != 'separator' && field.type.view != 'separatorbar' && field.type.view != 'empty_space' ? '' : ' hide')}>{field.title}<em className={!field.required ? 'hide' : ''}>*</em> : <sup className={field.help != '' && field.help != null && field.help != undefined ? '' : 'hide'}> [ <a title={field.helpcontent} onClick={() => this._FieldFunction('sup_' + field.name, field.value)}>{field.help}</a> ]</sup></label>

                <div style={{ maxHeight:'35px'}}>
                    {this._FieldComponent(field)}
                </div>
            </div>
        )
    }


    render() {

        return (
            <div>
                { this.state.fields.map((field, i) => (
                    <div key={'field_' + i}>
                        <div id={'_' + field.name} className="form-group pull-left" style={{ width: field.width || 0, padding: '3px', marginTop: field.margintop || 0, display: (field.visible == false?'none':'block') }}>
                            {this._InlineComponent(field)}
                        </div>
                    </div>

                ))}
                <div className="clear"></div>
            </div>
        )
    }
}

 export default FormGenerator;
