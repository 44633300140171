/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { UploadService } from '../../scripts/UploadService';
import moment from 'moment';
import $ from 'jquery';
import './Banners.css';


class Caixa extends Component {
    domain = "https://dlbrasiladmin.codemill.com.br";


    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            isLoading: false,
            selected_banner: {name:"", image: "", link: "", dimensions:""}
        }

        this._HandlePropertyChange = this._HandlePropertyChange.bind(this);
    }


    componentDidMount() {

    }


    componentWillMount() {
        this.Init();
    }


    componentWillReceiveProps(nextProps) {
        
    }


    Init = async () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Banners/Load"))
            .then((data) => {
                this.setState({
                    banners: data,
                    isLoading: false
                })
            });
        
    }


    _HandlePropertyChange = (event) => {
        let _banner = this.state.selected_banner;
        _banner[event.target.id] = event.target.value;
      
        let _index = this.state.banners.find(c => c.banner_id == _banner.banner_id);
        let _banners = this.state.banners;
        _banners[_index] = _banner;

        this.setState({ selected_banner: _banner, banners: this.state.banners });
    }


    _UploadImages = (selector) => {
        if (this.state.selected_banner.banner_id != "") {
            if (this.state.selected_banner.dimensions.length > 0) {
                document.getElementById(selector).click();
                $(document.getElementById(selector)).bind('change', (e) => {
                    this.setState({ isLoading: true });
                    let arr = document.getElementById(selector).files;

                    let uploadService = new UploadService();
                    for (let i = 0; i < arr.length; i++) {
                        uploadService.Queue(arr[i]);
                    }


                    let _width = this.state.selected_banner.dimensions.split('x')[0];
                    let _height = this.state.selected_banner.dimensions.split('x')[1];

                    uploadService.Start("api/Banners/Upload?banner_id=" + this.state.selected_banner.banner_id + "&width=" + _width + "&height=" + _height, (result) => {
                        //OnProgress
                        console.log("OnProgress", result.percent);
                    }, (result) => {
                        //OnEnd
                        console.log("OnEnd", result);
                        let _banner = this.state.selected_banner;
                        this.state.banners.find(c => c.banner_id == _banner.banner_id).image = this.domain + "/db/" + result.img;
                        this.setState({ isLoading: false, selected_banner: _banner, banners: this.state.banners });
                    });
                });
            }
        }
    }


    _SaveBanner = () => {
        let _banner = Object.assign(this.state.selected_banner, { token: this.props.user.token });
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost(_banner, "api/Banners/Save"))
            .then((data) => {
                this.setState({ isLoading: false });
            });
    }


    _BannerClick = (item) => {
        this.state.selected_banner = item;
        this.setState({ selected_banner: this.state.selected_banner });

        console.log(this.state.selected_banner);
    }


    render() {

        return (
            <div className="Banners">
                <LoadingOverlay>
                    <div className="row">
                            <div className="col-lg-8">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title">
                                        <h5>Banners do Site</h5>
                                    </div>


                                    <div className="ibox-content">
                                        {this.state.banners.map((item, i) => (
                                            <div key={'banner_' + i}>
                                                <a onClick={() => this._BannerClick(item)}>
                                                    <h4>{item.name} ({item.dimensions})</h4><br />
                                                    <img className="img" src={item.image} alt={item.name} />
                                                </a>
                                                <hr />  
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-content" id="banner_info">
                                        <div className="row">
                                            <center>
                                                <h3>{this.state.selected_banner.name} ({this.state.selected_banner.dimensions})</h3>
                                            </center>
                                        </div>
                                        <div className="row">
                                            <input id="uploadfiles" name="uploadfiles" className="hide" type="file" accept="image/*" />
                                            <button className="btn btn-white btn-sm wfull" onClick={() => this._UploadImages('uploadfiles')}>Imagem do Banner</button>

                                            <ul className="list-group clear-list">
                                                <li className="list-group-item fist-item">
                                                    <span className="pull-right"> Link do Banner</span>
                                                    <input id="list" type="text" className="form-control" value={this.state.selected_banner.link} onChange={this._HandlePropertyChange} />
                                                </li>

                                            </ul>

                                            <br />
                                            <button className="btn btn-white btn-sm" title="Salva Banner" type="button" disabled={!(this.state.selected_banner.banner_id != '' && this.state.selected_banner.banner_id != undefined && this.state.selected_banner.banner_id != null)} onClick={() => this._SaveBanner()}><i className="fa fa-check"></i> Salvar Banner</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>

 
                    <Loader fullPage loading={this.state.isLoading} text="Carregando..." />
                </LoadingOverlay>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Caixa);