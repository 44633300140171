import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Login from './components/Login/Login';
import Posts from './components/Posts/Posts';
import Post from './components/Posts/Post';
import Enquete from './components/Enquete/Enquete';
import Page from './components/Page/Page';
import FanArts from './components/FanArts/FanArts';
import Caixas from './components/Caixas/Caixas';
import Caixa from './components/Caixas/Caixa';
import Cartas from './components/Cartas/Cartas';
import Decks from './components/Decks/Decks';
import Tierlist from './components/Tierlist/Tierlist';
import Archtypes from './components/Archtypes/Archtypes';
import Banners from './components/Banners/Banners';

export default () => (
    <Layout>
        <Route exact path='/' component={Login} />
        <Route exact path='/Login' component={Login} />
        <Route exact path='/Posts' component={Posts} />
        <Route exact path='/Post' component={Post} />
        <Route exact path='/Post/:postId' component={Post} />
        <Route exact path='/Enquete' component={Enquete} />
        <Route exact path='/Page' component={Page} />
        <Route exact path='/FanArts' component={FanArts} />
        <Route exact path='/Caixas' component={Caixas} />
        <Route exact path='/Caixa' component={Caixa} />
        <Route exact path='/Caixa/:boxId' component={Caixa} />
        <Route exact path='/Cartas' component={Cartas} />
        <Route exact path='/Decks' component={Decks} />
        <Route exact path='/Tierlist' component={Tierlist} />
        <Route exact path='/Archtypes' component={Archtypes} />
        <Route exact path='/Banners' component={Banners} />
    </Layout>
);
