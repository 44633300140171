/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { Globals } from '../../scripts/Globals.js';
import InfiniteScroll from "react-infinite-scroll-component";
import $ from 'jquery';
import './Enquete.css';

import Cadastro from './Cadastro';



class Enquete extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            hasMore: true,
            total: 300,
            word: ""
        };

        this._handerWordChange = this._handerWordChange.bind(this);
    }


    componentDidMount() {
        Globals.AddComponent("enquete", this);
        Globals.Require(["enquete", "enquete_cadastro"]);
    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    Init = () => {
        Promise.resolve(RequestPost({ word: this.state.word, token: this.props.user.token }, "api/Enquete/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.state.total = data.total;

                this.setState({
                    total: data.total,
                    list: this.state.list.concat(data.enquetes)
                });
            });
    }

    fetchMoreData = () => {
        if (this.state.list.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        Promise.resolve(RequestPost({ word: this.state.word, token: this.props.user.token }, "api/Enquete/List?page=" + this.page + "&interval=50&offset=" + this.offset))
            .then((data) => {
                this.setState({
                    items: this.state.list.concat(data.list)
                })
            });
    };


    _handerWordChange = (event) => {
        let _word = event.target.value;
        this.setState({ word: _word });
    }


    _Reload = () => {
        this.setState({
            total: 0,
            list: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _OnEnqueteClick = (item) => {
        Globals.Run("enquete_cadastro", "Init", item);
    }


    _OnAtivoClick = (item, index) => {
        /*item.ativo = item.ativo == 0 ? 1 : 0;

        Promise.resolve(RequestPost({ enquete_id: item.enquete_id, ativo: item.ativo, token: this.props.user.token }, "api/Enquete/Ativo"))
            .then((data) => {
                let _list = [...this.state.list];
                _list[index].ativo = item.ativo;
                this.setState({ list: _list });
            });*/
    }


    render() {
        return (
            <div className="Enquete row">
                <div className="col col-sm-8">
                    <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Lista de Enquetes</h2>
                                <p>
                                    A lista inclui todas enquetes cadastradas.
                                 </p>
                            </div>
                        </div>
                        <div className="input-group">
                            <input type="text" placeholder="Buscar por nome" className="input form-control" value={this.state.word} onChange={this._handerWordChange} />
                            <span className="input-group-btn">
                                <button type="button" className="btn btn-primary" ng-click="OnFilterChange(search.word)"> <i className="fa fa-search"></i> Busca</button>
                                <button type="button" className="btn btn-success" ng-click="OnEnqueteNew()"> <i className="fa fa-newspaper-o"></i> Novo</button>
                            </span>
                        </div>
                        <div className="clients-list">
                            
                            
                                <Tabs className="tabActiveLinkStyle">
                                    <TabLink to="tab1" className="tabLink">Enquetes</TabLink>


                                    <TabContent for="tab1" className="tabContent">
                                        <div className="table">
                                            <InfiniteScroll
                                                dataLength={this.state.list.length}
                                                next={this.fetchMoreData}
                                                hasMore={this.state.hasMore}
                                                loader={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Carregando...</b>
                                                    </p>
                                                }
                                                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>Não há mais dados</b>
                                                    </p>
                                                }>

                                                {this.state.list.map((item, i) => (
                                                    <div key={'item' + i} className="tr">
                                                        <div className="td title" onClick={() => this._OnEnqueteClick(item)}>{item.question}</div>
                                                        <div className="td data" onClick={() => this._OEnqueteClick(item)}>{item.data}</div>
                                                        <div className="td action"><a onClick={() => this._OnAtivoClick(item, i)}><span className={'label' + (item.ativo == 1 ? ' label-primary' : ' label-info')}>{item.ativo == 1 ? 'Ativo' : 'Inativo'}</span></a></div>
                                                        <div className="cleaner">&nbsp;</div>
                                                    </div>
                                                ))}
                                            </InfiniteScroll>
                                        </div>


                                    </TabContent>
                                </Tabs>



                               



                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-sm-4">
                    <Cadastro />
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Enquete);