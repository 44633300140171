import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Auth';
import { TimeAgo } from '../scripts/DateTimeFilter'


class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            last: ""
        };
    }


    componentWillMount() {
        if (this.props.user.lastAccess) {
            this.setState({ last: TimeAgo(this.props.user.lastAccess) });
        }
    }




    render() {
        return (
            <div>
                <div className="pull-right">
                    <span className="txt-color-blueLight hidden-mobile">Último login do sistema <i className="fa fa-clock-o"></i> {this.state.last}<strong></strong> </span>
                </div>
                <div>
                    <strong>Copyright</strong> DLBRASIL & CODEMILL &copy; 2020 - Recomendado com Google Chrome
                </div>
            </div>
        );
    }
}


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Footer);