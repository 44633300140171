/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { RequestGet, RequestPost } from '../../scripts/Request.js';
import { Globals } from '../../scripts/Globals.js';
import $ from 'jquery';
import './Cadastro.css';



class Cadastro extends Component {
    option_source = { id: "", enqueteid: "", description: "", votes: 0 };


    constructor(props) {
        super(props);

        this.state = {
            id: "",
            question: "",
            list: [],
            ativo: false
        };

        this._HandlerChange = this._HandlerChange.bind(this);
        this._HandlerListChange = this._HandlerListChange.bind(this);
    }


    componentDidMount() {
        Globals.AddComponent("enquete_cadastro", this);
        Globals.Require(["enquete", "enquete_cadastro"]); 
    }


    componentWillMount() {
        this._More();
    }


    Init = (args) => {
        //console.log(args);
        this.setState({ id: args.id, question: args.question, list: args.options, ativo:(args.ativo=="1") });
    }


    _HandlerChange = (event) => {
        if (event.target.type == "checkbox") {
            //console.log(event.target.type, event.target.checked);
            this.setState({ [event.target.id]: event.target.checked });
        } else {
            this.setState({ [event.target.id]: event.target.value });
        }
    }


    _HandlerListChange = (value, index) => {
        const _list = [...this.state.list];
       _list[index].description = value;
        this.setState({ list: _list });


    }


    _DeleteItem = (item) => {
        const _list = [...this.state.list];
        let _index = _list.findIndex(l => l.id);
        _list.splice(_index, 1);
        this.setState({ list: _list });
    }


    _Delete = () => {
        this.setState({ id: "", question: "", list: [], ativo: false });
        this._More();
    }


    _SaveEnquete = () => {
        let _data = this.state;
        _data.ativo = _data.ativo ? 1 : 0;
        Promise.resolve(RequestPost({ enquete: _data, token: this.props.user.token }, "api/Enquete/Save"))
            .then((data) => {
                this.setState({ id: "", question: "", list: [], ativo: false });
                this._More();
                Globals.Run("enquete", "_Reload");
            });
    }


    _More = () => {
        let _list = [...this.state.list];
        let _copy = {};
        Object.assign(_copy, this.option_source);
        
        _list.push(_copy);

        this.setState({
            list: _list
        }) 
    }


    render() {

        return (
            <div className="Cadastro ibox">
                <div className="ibox-content">
                    <div className="tab-content">
                        <div id="contact-1" className="tab-pane active">
                            <div className="row m-b-lg">

                            </div>
                            <div>
                                <div>
                                    <strong>Cadastro de Enquete</strong><br />
                                    <span>Apenas uma enquete pode estar ativa no site!</span>

                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                                            <span className="pull-right"> Pergunta</span>
                                            <input id="question" type="text" className="form-control" value={this.state.question} onChange={this._HandlerChange} />
                                        </li>

                                        <li className="list-group-item">
                                            <div>Opções</div>
                                        </li>
                                        <li className="list-group-item">

                                            <table border="0" cellPadding="0" cellSpacing="0">
                                                <tbody>
                                                    {this.state.list.map((item, i) => (
                                                        <tr key={'la'+i}>
                                                            <td className="index">{(i + 1)}. </td>
                                                            <td className="description"><input id={'t' + i} type="text" className="form-control" defaultValue="" value={item.description} onChange={(e) => this._HandlerListChange(e.target.value, i)} /></td>
                                                            <td className="delete"><button className={'btn btn-white btn-sm' + (i == 0 ? ' hide': '')} type="button" onClick={() => this._DeleteItem(item)}><i className="fa fa-trash"></i></button></td>
                                                        </tr>
                                                    ))}
                                                 </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="3"><button type="button" className="btn btn-white btn-sm pull-right btn_more" onClick={() => this._More()}><i className="fa fa-plus"></i> Mais uma Opção</button></td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </li>


                                    </ul>

                                    <hr />
                                    <p>

                                        <button className="btn btn-red btn-sm" data-toggle="tooltip" data-placement="left" title="Apagar" onClick={() => this._Delete()}><i className="fa fa-recycle"></i> Apagar</button>
                                        <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Salvar" onClick={() => this._SaveEnquete()}><i className="fa fa-check"></i> Salvar</button>
                                        &nbsp;<input id="ativo" type="checkbox" className="icheck" checked={this.state.ativo} onChange={this._HandlerChange} /> Ativo
                                    </p>

                                   <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Cadastro);