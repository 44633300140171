/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { RequestPost } from '../../scripts/Request.js';
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import './Tierlist.css';


class Tierlist extends Component {
    page = 0;
    offset = 0;

    constructor(props) {
        super(props);
        this.state = {
            search: { word: ""},
            list: [],
            hasMore: true,
            total: 300,
            isLoading: false
        }
    }


    componentDidMount() {

    }


    componentWillMount() {
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    Init = async () => {
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Tierlist/List?page=" + this.page + "&interval=100&offset=" + this.offset))
            .then((result) => {
                this.setState({ isLoading: false, list: this.state.list.concat(result.list), total: result.total });
            });

    }



    fetchMoreData = () => {
        if (this.state.list.length >= this.state.total) {
            this.setState({ hasMore: false });
            return;
        }

        this.page++;
        this.offset++;
        this.setState({ isLoading: true });
        Promise.resolve(RequestPost({ token: this.props.user.token }, "api/Tierlist/List?page=" + this.page + "&interval=100&offset=" + this.offset))
            .then((result) => {
                this.setState({
                    list: this.state.list.concat(result.list),
                    isLoading: false
                })
            });
    };


    _Reload = () => {
        this.setState({
            total: 0,
            list: []
        });
        this.page = 0;
        this.offset = 0;
        this.Init();
    }


    _OnTierChange = (item, field, event) => {
        let _list = this.state.list;
        _list.find(l => l.arch_id == item.arch_id)[field] = event.target.value;

        this.setState({ list: _list });

        if (field == "tier") {
            this._OnBlurChange(item, event);
        }
    }


    _OnBlurChange = (item, event) => {
        Promise.resolve(RequestPost({ token: this.props.user.token, arch_id: item.arch_id, tier: item.tier, description: item.description, link: item.link}, "api/Tierlist/Change"))
            .then((result) => {
                
            });
    }


    _HandleSearchChange = (event) => {
        let _search = this.state.search;
        _search.word = event.target.value;

        let _list = this.state.list;
        _list = _list.filter((item) => {
            return (item.tier!="") || (item.tier == "" && item.name.toLowerCase().indexOf(_search.word.toString().toLowerCase().toLowerCase())>-1);
        });

        this.setState({ search: _search, list: _list })
    }


    render() {
        const { list, search } = this.state;
        let tier0 = list.filter(t => t.tier == "0");
        let tier1 = list.filter(t => t.tier == "1");
        let tier2 = list.filter(t => t.tier == "2");
        let tier3 = list.filter(t => t.tier == "3");
        let tiern = list.filter(t => t.tier == "");

        return (
            <div className="Tierlist row">
                <div className="ibox">
                    <InfiniteScroll
                        dataLength={this.state.list.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={
                            <p style={{ textAlign: "center" }}>
                                <b>Carregando...</b>
                            </p>
                        }
                        _style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Não há mais dados</b>
                            </p>
                        }>

                        <div className="ibox-content tlayout">
                            <h2>TIER 0</h2>
                            {tier0.map((item, i) => (
                                <div key={'t0'+i} className="tier bg0">
                                    <div className="name"><center>{item.name}</center></div>
                                    <div className="img"><center><img src={'https://images.weserv.nl/?w=102&h=149&url=' + item.image +'&temp'} /></center></div>
                                    <div className="tieritem">
                                        <select id={'tier0_'+i} className="form-control" value={item.tier} onChange={(event) => this._OnTierChange(item, 'tier', event)} >
                                            <option value="">Sem Tier</option>
                                            <option value="0">Tier Zero</option>
                                            <option value="1">Tier 1</option>
                                            <option value="2">Tier 2</option>
                                            <option value="3">Tier 3</option>
                                        </select>
                                    </div>
                                    <div className="description"><textarea id={'tier0desc_' + i} className="form-control" value={item.description} style={{ height: '90px' }} onChange={(event) => this._OnTierChange(item, 'description', event)} onBlur={(event) => this._OnBlurChange(item, event)}>{ item.description }</textarea></div>
                                    <div className="link"><input id={'tier0link_' + i} type="text" className="form-control" value={item.link} onChange={(event) => this._OnTierChange(item, 'link', event)} onBlur={(event) => this._OnBlurChange(item, event)} placeholder="link" /></div>
                                </div>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                            <br />

                            <h2>TIER 1</h2>
                            {tier1.map((item, i) => (
                                <div key={'t1' + i} className="tier bg0">
                                    <div className="name"><center>{item.name}</center></div>
                                    <div className="img"><center><img src={'https://images.weserv.nl/?w=102&h=149&url=' + item.image + '&temp'} /></center></div>
                                    <div className="tieritem">
                                        <select id={'tier1_' + i} className="form-control" value={item.tier} onChange={(event) => this._OnTierChange(item, 'tier', event)} >
                                            <option value="">Sem Tier</option>
                                            <option value="0">Tier Zero</option>
                                            <option value="1">Tier 1</option>
                                            <option value="2">Tier 2</option>
                                            <option value="3">Tier 3</option>
                                        </select>
                                    </div>
                                    <div className="description"><textarea id={'tier1desc_' + i} className="form-control" value={item.description} style={{ height: '90px' }} onChange={(event) => this._OnTierChange(item, 'description', event)} onBlur={(event) => this._OnBlurChange(item, event)}>{item.description}</textarea></div>
                                    <div className="link"><input id={'tier1link_' + i} type="text" className="form-control" value={item.link} onChange={(event) => this._OnTierChange(item, 'link', event)} onBlur={(event) => this._OnBlurChange(item, event)} placeholder="link" /></div>
                                </div>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                            <br />

                            <h2>TIER 2</h2>
                            {tier2.map((item, i) => (
                                <div key={'t2' + i} className="tier bg0">
                                    <div className="name"><center>{item.name}</center></div>
                                    <div className="img"><center><img src={'https://images.weserv.nl/?w=102&h=149&url=' + item.image + '&temp'} /></center></div>
                                    <div className="tieritem">
                                        <select id={'tier2_' + i} className="form-control" value={item.tier} onChange={(event) => this._OnTierChange(item, 'tier', event)} >
                                            <option value="">Sem Tier</option>
                                            <option value="0">Tier Zero</option>
                                            <option value="1">Tier 1</option>
                                            <option value="2">Tier 2</option>
                                            <option value="3">Tier 3</option>
                                        </select>
                                    </div>
                                    <div className="description"><textarea id={'tier2desc_' + i} className="form-control" value={item.description} style={{ height: '90px' }} onChange={(event) => this._OnTierChange(item, 'description', event)} onBlur={(event) => this._OnBlurChange(item, event)}>{item.description}</textarea></div>
                                    <div className="link"><input id={'tier2link_' + i} type="text" className="form-control" value={item.link} onChange={(event) => this._OnTierChange(item, 'link', event)} onBlur={(event) => this._OnBlurChange(item, event)} placeholder="link" /></div>
                                </div>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                            <br />


                            <h2>TIER 3</h2>
                            {tier3.map((item, i) => (
                                <div key={'t3' + i} className="tier bg0">
                                    <div className="name"><center>{item.name}</center></div>
                                    <div className="img"><center><img src={'https://images.weserv.nl/?w=102&h=149&url=' + item.image + '&temp'} /></center></div>
                                    <div className="tieritem">
                                        <select id={'tier3_' + i} className="form-control" value={item.tier} onChange={(event) => this._OnTierChange(item, 'tier', event)} >
                                            <option value="">Sem Tier</option>
                                            <option value="0">Tier Zero</option>
                                            <option value="1">Tier 1</option>
                                            <option value="2">Tier 2</option>
                                            <option value="3">Tier 3</option>
                                        </select>
                                    </div>
                                    <div className="description"><textarea id={'tier3desc_' + i} className="form-control" value={item.description} style={{ height: '90px' }} onChange={(event) => this._OnTierChange(item, 'description', event)} onBlur={(event) => this._OnBlurChange(item, event)}>{item.description}</textarea></div>
                                    <div className="link"><input id={'tier3link_' + i} type="text" className="form-control" value={item.link} onChange={(event) => this._OnTierChange(item, 'link', event)} onBlur={(event) => this._OnBlurChange(item, event)} placeholder="link" /></div>
                                </div>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                            <br />


                            <h2>SEM TIER</h2>
                            <div style={{ width: '300px' }}><input type="text" id="word" className="form-control" value={search.word} onChange={this._HandleSearchChange}/></div>
                            {tiern.map((item, i) => (
                                <div key={'tn' + i} className="tier bg0">
                                    <div className="name"><center>{item.name}</center></div>
                                    <div className="img"><center><img src={'https://images.weserv.nl/?w=102&h=149&url=' + item.image + '&temp'} /></center></div>
                                    <div className="tieritem">
                                        <select id={'tiern_' + i} className="form-control" value={item.tier} onChange={(event) => this._OnTierChange(item, 'tier', event)} >
                                            <option value="">Sem Tier</option>
                                            <option value="0">Tier Zero</option>
                                            <option value="1">Tier 1</option>
                                            <option value="2">Tier 2</option>
                                            <option value="3">Tier 3</option>
                                        </select>
                                    </div>
                                    <div className="description"><textarea id={'tierndesc_' + i} className="form-control" value={item.description} style={{ height: '90px' }} onChange={(event) => this._OnTierChange(item, 'description', event)} onBlur={(event) => this._OnBlurChange(item, event)}>{item.description}</textarea></div>
                                    <div className="link"><input id={'tiernlink_' + i} type="text" className="form-control" value={item.link} onChange={(event) => this._OnTierChange(item, 'link', event)} onBlur={(event) => this._OnBlurChange(item, event)} placeholder="link" /></div>
                                </div>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                            <br />
                        </div>

                    </InfiniteScroll>
                </div>
            </div>
        )
    }
}


export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Tierlist);