/* eslint no-undef: 0 */ // --> OFF
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import './Login.css';


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                login: "",
                password: ""
            },
            isLoading: false
        }

        this._OnLoginChange = this._OnLoginChange.bind(this);
        this._OnPasswordChange = this._OnPasswordChange.bind(this);
    }


    componentWillMount() {
        this.props.clearCredentials();
        document.body.style.background = "url('img/fundo.jpg')";
        console.log("Login");
    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate() {
        if (this.props.isError != undefined) {
            if (this.props.isError == true) {
                alert("Usuário ou senha incorretos!");
            } else if (this.props.isError == false && this.props.user.name != "") {
                document.body.style.background = "";
                this.props.history.push("/Posts")
            }
        }
    }


    _Login = (event) => {
        this.props.requestAccess(this.state.form);
        event.preventDefault();
    }

    _OnLoginChange(event) {
        let _form = this.state.form;
        _form.login = event.target.value;
        this.setState({ form: _form });
    }

    _OnPasswordChange(event) {
        let _form = this.state.form;
        _form.password = event.target.value;
        this.setState({ form: _form });
    }


    render() {
        return (
            <div className="loginColumns animated fadeInDown">
                <div className="row">
                    <br /><br /><br />
                </div>

                <div className="row">
                    <div className="col-md-1">

                    </div>
                    <div className="col-md-4">
                        <p>
                            <img src="img/logo.png" alt="" />
                        </p>
                    </div>
                    <LoadingOverlay>
                        <div className="col-md-6">
                            <div id="login-content" className="ibox-content">
                                <form className="_m-t" role="form" autoComplete="false" onSubmit={this._Login}>
                                    <div className="form-group">
                                        <input id="login" name="login" type="text" className="form-control" placeholder="login" required="required" value={this.state.form.login} onChange={this._OnLoginChange} />
                                    </div>
                                    <div className="form-group">
                                        <input id="password" name="password" type="password" className="form-control" placeholder="senha" required="required" value={this.state.form.password} onChange={this._OnPasswordChange} />
                                    </div>
                                    <button type="submit" className="btn btn-primary block full-width m-b" ng-disabled="!is_chrome">Entrar</button>
                       
                                    {/*<a href="#" data-toggle="modal" data-target="#myModal" prevent>
                                        <small>Esqueceu sua senha?</small>
                                    </a>*/}
                                </form>

                                <p className="m-t">
                                    <small></small>
                                </p>
                            </div>
                        </div>
                        <Loader fullPage loading={this.props.isLoading} text="Carregando..." />
                    </LoadingOverlay>

                    <div className="col-md-1">

                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-6 text-right">
                        <small>DLBRASIL & CODEMILL © 2021</small>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);